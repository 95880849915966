import { client } from '../client'
import { LogModel } from '@/globals/models/LogModel'
import { BaseService } from './base-service'

export const LogService = {
  _apiEndpoint: BaseService.apiEndpoint + 'log/',

  async createLog (log: LogModel) {
    const url = this._apiEndpoint

    return await client.post(url, log)
  }
}
