













































































































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import LicensePlate from '@/globals/components/license-plate/LicensePlate.vue'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierStatusEnum } from '@/globals/enums/DossierStatus'
import DossierStatusComponent from '@/globals/components/dossier-status/DossierStatus.vue'
import { ExportService } from '@/network/api/export-service'
import { ExportController } from '@/globals/controllers/ExportController'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import ExportButton from './export-button/ExportButton.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DateHelper } from '@/globals/helpers/DateHelper'
import FilterDate from './filter-date/FilterDate.vue'
import { ClientModel } from '@/globals/models/ClientModel'
import ActionButton from './action-button/ActionButton.vue'
import ChangeDossiersStateModal from '../change-dossiers-state-modal/ChangeDossiersStateModal.vue'
import { ObjectHelper } from '@/globals/helpers/ObjectHelper'
import { LicenseHelper } from '@/globals/helpers/LicenseHelper'
import HorizontalTabbar from '@/globals/components/tabbar/HorizontalTabbar.vue'
import DossierDanger from '@/views/dossier/components/dossier-danger/DossierDanger.vue'
import { DossierAttachmentEnum } from '@/globals/enums/DossierAttachment'
import ChangeInHubModal from '../change-in-hub-modal/ChangeInHubModal.vue'
import moment from 'moment'

export default Vue.extend({
  props: {
    _isLoading: {
      type: Boolean,
      required: true
    },
    _dossiers: {
      required: true,
      default: () => []
    },
    _clients: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    InputField,
    PrimaryButton,
    LicensePlate,
    DossierStatusComponent,
    Dropdown,
    ExportButton,
    FilterDate,
    ActionButton,
    ChangeDossiersStateModal,
    HorizontalTabbar,
    DossierDanger,
    ChangeInHubModal
  },
  data () {
    return {
      data: [] as Array<any>,
      selected: [] as Array<any>,
      dossierStatus: new DossierStatusEnum(),
      dossierAttachmentEnum: new DossierAttachmentEnum(),
      isEmployee: this.$store.getters['user/isEmployee'],
      dateHelper: DateHelper,
      sortBy: ['updated_at_unix'],
      sortDesc: [true],
      tabs: [] as Array<{ value: string; name: string; active: boolean; enabled: boolean }>,
      search: '',
      selectedFilterClient: null as null|number,
      selectedFilterStatus: null as null|number,
      selectedFilterDates: null as null|Array<Date|null>,
      selectedFilterDate: 2,
      selectedFilterOreh: null as null|boolean,
      selectedFilterOthers: [] as Array<string>,
      filterOrehSelections: [
        { value: null, text: 'Toon alle' },
        { value: true, text: 'Met OREH' },
        { value: false, text: 'Zonder OREH' }
      ],
      filterOtherSelections: [
        { value: 'is-at-hub', text: 'Auto binnen' },
        { value: 'car-petrol', text: 'Auto benzine 3-4 jaar' },
        { value: 'wltp', text: 'WLTP' },
        { value: 'temporary', text: 'Tijdelijk documentnummer' },
        { value: 'invalid-apk', text: 'Ongeldige APK' },
        { value: 'invalid-apk-7-days', text: 'APK verloopt binnen 7 dagen' },
        { value: 'no-approval-type', text: 'Geen type-goedkeuringsnummer' },
        { value: 'gas-vehicles', text: 'Gasvoertuig' },
        { value: 'bpm', text: 'BPM' },
        { value: 'has-kfz', text: 'Heeft KFZ' }
      ],
      client: new ClientModel(),
      isNewDossierModal: false,
      isChangeDossiersStateModal: false,
      isChangeInHubModal: false,
      headers: [] as Array<any>
    }
  },
  computed: {
    isClient (): boolean {
      return this.$store.getters['user/isClient']
    },
    isAdministration (): boolean {
      return this.$store.getters['user/isAdministration']
    },
    isAdesa (): boolean {
      return this.$store.getters['user/isAdesa']
    },
    filterStatuses (): Array<any> {
      const statuses = this.isAdesa ? this.dossierStatus.getStatusSelectionAdesa() : this.dossierStatus.getStatusSelection()
      const additional = { value: null, text: 'Alle statussen' }
      statuses.unshift(additional)
      return statuses
    },
    filterClients (): Array<any> {
      const clients = this._clients.slice()
      const additional = { id: null, name: 'Alle klanten' }
      clients.unshift(additional)
      return clients
    },
    currentTab (): any {
      return this.tabs.find(t => t.active)
    },
    filteredData (): any {
      return this.data.filter((item) => {
        let isValid = this.filterTabs(item)
        isValid = isValid ? this.filterClient(item) : false
        isValid = isValid ? this.filterStatus(item.dossier) : false
        isValid = isValid ? this.filterOreh(item.dossier) : false
        isValid = isValid ? this.filterDate(item) : false
        isValid = isValid ? this.filterOthers(item) : false

        return isValid
      })
    }
  },
  watch: {
    _dossiers () {
      this.data = []
      this.selected = []
      this.setData()
    },
    selectedFilterDates () {
      this.$emit('get_dossiers', this.selectedFilterDates)
    }
  },
  beforeMount () {
    this.setTableHeaders()
    this.setTabs()
    this.setFilters()
    this.setData()

    if (this.isClient) {
      this.setClient()
    }
  },
  methods: {
    setTableHeaders () {
      this.headers = [
        { text: 'KENTEKEN', value: 'plate', width: 140 },
        { text: 'KENTEKEN NO STRIPES', value: 'plate_plain', width: 100, align: ' d-none' },
        { text: 'CAR-ID', value: 'car_id', width: 100, align: this.isAdministration ? ' d-none' : '' },
        { text: 'MERK', value: 'brand', width: 120 },
        { text: 'VIN', value: 'vin_number', width: 170 },
        { text: 'KLANT', value: 'client', width: 100, align: this.isClient ? ' d-none' : '' },
        { text: 'OREH', value: 'oreh', width: 90, align: this.isClient ? ' d-none' : '' },
        { text: 'AUTO BINNEN', value: 'in_hub', width: 135 },
        { text: 'STUKKEN', value: 'has_missing_data', width: 200 },
        { text: 'AANGEPAST OP', value: 'updated_at_unix', width: 140 },
        { text: 'STATUS', sortable: false, value: 'status', width: 240 }
      ]
    },

    setTabs () {
      this.tabs = [
        { value: 'all', name: 'Alles', active: true, enabled: true },
        { value: 'incomplete', name: 'Incompleet', active: false, enabled: true },
        { value: 'datenblatt', name: 'Datenblatt', active: false, enabled: this.isAdministration },
        { value: 'complete', name: this.isAdministration ? 'Klaar voor export' : 'Compleet', active: false, enabled: true },
        { value: 'kfz', name: 'Klaar voor KFZ', active: false, enabled: this.isAdministration }
      ]
    },
    filterTabs (item: any) {
      if (this.currentTab.value === 'complete') {
        return this.filterTabComplete(item)
      } else if (this.currentTab.value === 'incomplete') {
        return this.filterTabInComplete(item)
      } else if (this.currentTab.value === 'datenblatt') {
        return this.filterTabDatenBlatt(item)
      } else if (this.currentTab.value === 'kfz') {
        return this.filterReadyForKfz(item)
      } else {
        return true
      }
    },
    filterTabComplete (item: any): boolean {
      return this.isAdesa ? (item.dossier as DossierModel).isCompleteAdesa() : (item.dossier as DossierModel).isComplete()
    },
    filterTabInComplete (item: any): boolean {
      return this.isAdesa ? !(item.dossier as DossierModel).isCompleteAdesa() : !(item.dossier as DossierModel).isComplete()
    },
    filterTabDatenBlatt (item: any): boolean {
      return (item.dossier as DossierModel).vehicle.has_wltp && !item.has_datenblatt
    },
    filterReadyForKfz (item: any): boolean {
      return !(item.dossier as DossierModel).vehicle.isOreh() && (item.dossier as DossierModel).isComplete() && (item.dossier as DossierModel).isAttachmentUploaded(this.dossierAttachmentEnum.Export.value) && !(item.dossier as DossierModel).isAttachmentUploaded(this.dossierAttachmentEnum.KfzLetter.value)
    },

    filterClient (item: any): boolean {
      let valid = false

      if (!this.selectedFilterClient) {
        valid = true
      }
      if (this.selectedFilterClient && this.selectedFilterClient === (item.dossier as DossierModel).client.id) {
        valid = true
      }

      return valid
    },

    filterStatus (dossier: DossierModel): boolean {
      let valid = false
      if (this.selectedFilterStatus === null) {
        valid = true
      }
      if (this.selectedFilterStatus !== null && this.selectedFilterStatus === dossier.status) {
        valid = true
      }

      if (this.isAdesa && this.selectedFilterStatus !== null) {
        valid = this.filterAdesaStatus(dossier, valid)
      }

      return valid
    },
    filterAdesaStatus (dossier: DossierModel, valid: boolean): boolean {
      if (this.selectedFilterStatus === this.dossierStatus.Complete.value && dossier.isCompleteAdesa()) {
        valid = true
      }
      if (this.selectedFilterStatus !== this.dossierStatus.Complete.value && dossier.isCompleteAdesa()) {
        valid = false
      }

      return valid
    },

    filterOreh (dossier: DossierModel): boolean {
      let valid = false
      if (this.selectedFilterOreh === null) {
        valid = true
      } else if (this.selectedFilterOreh && dossier.vehicle.isOreh()) {
        valid = true
      } else if (!this.selectedFilterOreh && !dossier.vehicle.isOreh()) {
        valid = true
      }

      return valid
    },

    filterDate (item: any): boolean {
      let valid = false

      if (this.selectedFilterDates === null) {
        valid = true
      }
      if (this.selectedFilterDates !== null && this.selectedFilterDates[0] !== null && this.selectedFilterDates[0] <= item.updated_at_date) {
        if (this.selectedFilterDates[1] === null) {
          valid = true
        }
        if (this.selectedFilterDates[1] !== null && this.selectedFilterDates[1] >= item.updated_at_date) {
          valid = true
        }
      }

      return valid
    },

    filterOthers (item: any): boolean {
      let valid = true

      if (this.selectedFilterOthers.findIndex(f => f === 'is-at-hub') !== -1) {
        valid = this.filterCarAtHub(item)
      }

      if (this.selectedFilterOthers.findIndex(f => f === 'temporary') !== -1) {
        valid = this.filterTemporaryDocument(item)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'wltp') !== -1) {
        valid = this.filterWLTP(item)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'car-petrol') !== -1) {
        valid = this.filterCarPetrol(item.dossier)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'invalid-apk') !== -1) {
        valid = this.filterInvalidApk(item.dossier)
      }
      if (valid && this.selectedFilterOthers.findIndex(f => f === 'invalid-apk-7-days') !== -1) {
        valid = this.filterInvalidApkInSevenDays(item.dossier)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'no-approval-type') !== -1) {
        valid = this.filterNoApprovalType(item.dossier)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'gas-vehicles') !== -1) {
        valid = this.filterGasVehicles(item.dossier)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'bpm') !== -1) {
        valid = this.filterBpm(item)
      }

      if (valid && this.selectedFilterOthers.findIndex(f => f === 'has-kfz') !== -1) {
        valid = this.filterHasKfz(item.dossier)
      }

      return valid
    },
    filterCarAtHub (item: any): boolean {
      return (item.dossier as DossierModel).arrived_at_hub
    },
    filterTemporaryDocument (item: any): boolean {
      return item.dossier.isTemporaryDocumentComplete()
    },
    filterWLTP (item: any): boolean {
      return item.isWltpCar
    },
    filterCarPetrol (item: DossierModel): boolean {
      return item.vehicle.isPetrolCarDanger() && !item.vehicle.isMilageAbove100k()
    },
    filterInvalidApk (item: DossierModel): boolean {
      return !item.vehicle.isApkValid() || (item.vehicle.isPetrolCarDanger() && item.vehicle.isMilageAbove100k())
    },
    filterInvalidApkInSevenDays (item: DossierModel): boolean {
      let valid = false

      if (item.vehicle.apk_end_date) {
        const endDate = DateHelper.toMoment(item.vehicle.apk_end_date)
        const today = moment().startOf('day')
        const endsDifference = endDate.diff(today, 'days')
        valid = endsDifference > 0 && endsDifference <= 7
      }

      return valid
    },
    filterNoApprovalType (item: DossierModel): boolean {
      return item.vehicle.approval_type == null
    },
    filterGasVehicles (item: DossierModel): boolean {
      return item.vehicle.fuel.toLowerCase().includes('cng') || item.vehicle.fuel.toLowerCase().includes('lpg')
    },
    filterBpm (item: any): boolean {
      const dossier = (item.dossier as DossierModel)
      let valid = dossier.isComplete()
      valid = valid ? dossier.isAttachmentUploaded(this.dossierAttachmentEnum.Export.value) : false
      valid = valid ? dossier.isAttachmentUploaded(this.dossierAttachmentEnum.KfzLetter.value) : false
      valid = valid ? dossier.vehicle.net_bpm !== null : false
      valid = valid ? dossier.vehicle.foreign_license_plate !== null : false
      valid = valid ? dossier.vehicle.date_foreign_registration !== null : false
      return valid
    },
    filterHasKfz (dossier: DossierModel): boolean {
      return dossier.isAttachmentUploaded(this.dossierAttachmentEnum.KfzLetter.value)
    },

    setFilters () {
      this.selectedFilterClient = this.$store.state.dossier.filter.client
      this.selectedFilterStatus = this.$store.state.dossier.filter.status
      this.selectedFilterOreh = this.$store.state.dossier.filter.oreh

      if (this.$store.state.dossier.filter.others) {
        this.selectedFilterOthers = this.$store.state.dossier.filter.others
      }

      const date = this.$store.state.dossier.filter.date
      const from = this.$store.state.dossier.filter.from
      const to = this.$store.state.dossier.filter.to

      if (date) {
        this.selectedFilterDates = [] as Array<Date>
        this.selectedFilterDate = parseInt(date)

        this.selectedFilterDates[0] = from ? DateHelper.resetTime(from) : null
        this.selectedFilterDates[1] = to ? DateHelper.resetTime(to) : null
      }
    },

    setData () {
      this._dossiers.forEach((dossier: DossierModel) => {
        this.addTableItem(dossier)
      })
    },

    hasTemporaryDoc (attachments: any) {
      let found = false
      attachments.forEach((attach: any) => {
        if (attach.type === 5) {
          found = true
        }
      })
      return found
    },

    addTableItem (dossier: DossierModel) {
      const dataItem = {
        dossier: dossier,
        id: dossier.id,
        car_id: dossier.car_id === 0 ? null : dossier.car_id,
        updated_at_date: dossier.updated_at ? this.dateHelper.resetTime(dossier.updated_at) : this.dateHelper.resetTime(dossier.created_at),
        updated_at_datetime: dossier.updated_at ? this.dateHelper.toLocaleDateString(dossier.updated_at) : this.dateHelper.toLocaleDateString(dossier.created_at),
        updated_at_unix: dossier.updated_at ? this.dateHelper.toUnix(dossier.updated_at) : this.dateHelper.toUnix(dossier.created_at),
        plate: LicenseHelper.toLicensePlateStripes(dossier.license_plate),
        plate_plain: dossier.license_plate,
        brand: dossier.vehicle.brand,
        model: dossier.vehicle.model,
        vin_number: dossier.vehicle.vin_number,
        client: dossier.client.id,
        clientName: dossier.client.name,
        status: dossier.status,
        dossierStatus: this.dossierStatus.getByValue(dossier.status),
        oreh: dossier.vehicle.isOreh(),
        in_hub: dossier.arrived_at_hub,
        isWltpCar: dossier.vehicle.has_wltp,
        isPetrolCarDanger: (this.isAdministration || this.isEmployee) && dossier.vehicle.isPetrolCarDanger(),
        isDangerous: dossier.vehicle.is_dangerous,
        item_row: dossier.vehicle.is_dangerous || ((this.isAdministration || this.isEmployee) && dossier.vehicle.isPetrolCarDanger()) ? 'is--dangerouss' : null,
        is_selected: false,
        has_missing_data: !ObjectHelper.isEmpty(dossier.hasMissingData(this.isAdesa)) ? dossier.hasMissingData(this.isAdesa) : false,
        has_datenblatt: dossier.isAttachmentUploaded(new DossierAttachmentEnum().Datenblatt.value)
      }

      this.data.push(dataItem)
    },

    onTabChange (value: string) {
      this.tabs.forEach(tab => {
        tab.active = tab.value === value
      })
    },

    setClient () {
      if (this._dossiers.length) {
        const dossier = this._dossiers[0] as DossierModel
        this.client = dossier.client
      }
    },

    async exportDossiers () {
      await ExportService.getAllDossiersExcel().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'export-' + new Date().toLocaleString() + '.xlsx')
        }
      })
    },

    async exportFilteredDossiers () {
      const ids = [] as Array<number>
      this.filteredData.forEach((item: any) => {
        ids.push(item.id)
      })
      await ExportService.getDossiersExcel(ids, true).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'export-' + new Date().toLocaleString() + '.xlsx')
          this.client.last_export_date = DateHelper.resetTime(new Date())
          this.client.last_export_start = this.selectedFilterDates !== null ? this.selectedFilterDates[0] : null
          this.client.last_export_end = this.selectedFilterDates !== null && this.selectedFilterDates[1] ? this.selectedFilterDates[1] : DateHelper.resetTime(new Date())
        }
      })
    },

    async exportSelected () {
      const ids = [] as Array<number>
      this.selected.forEach(item => {
        ids.push(item.id)
      })
      await ExportService.getDossiersExcel(ids, false).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'selectie-export-' + new Date().toLocaleString() + '.xlsx')
        }
      })
    },

    async exportSelectedBpm () {
      const ids = [] as Array<number>
      this.selected.forEach(item => {
        ids.push(item.id)
      })
      await ExportService.getDossiersBpm(ids).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'selectie-export-bpm-' + new Date().toLocaleString() + '.xlsx')
        }
      })
    },

    async exportSelectedGermany () {
      const ids = [] as Array<number>
      this.selected.forEach(item => {
        ids.push(item.id)
      })
      this.exportGermanyExcel(ids)
    },

    async exportGermanyExcel (ids: Array<number>) {
      await ExportService.getDossiersZip(ids).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'selectie-export-' + new Date().toLocaleString() + '.zip')
        }
      })
    },

    setFilterClientQuery (client: string|null) {
      this.$store.commit('dossier/setFilterClient', client)
    },
    setFilterStatusQuery (status: number|null) {
      this.$store.commit('dossier/setFilterStatus', status)
    },
    setFilterOrehQuery (isOreh: boolean|null) {
      this.$store.commit('dossier/setFilterOreh', isOreh)
    },
    setFilterOthersQuery (data: Array<string>|null) {
      this.$store.commit('dossier/setFilterOthers', data)
    },
    setFilterDateQuery (data: null|{ dates: Array<Date>; selected: number}) {
      const selected = data ? data.selected : null
      const from = data ? DateHelper.toDateString(data.dates[0]) : null
      const to = data && data.dates[1] ? DateHelper.toDateString(data.dates[1]) : null

      this.$store.commit('dossier/setFilterDate', selected)
      this.$store.commit('dossier/setFilterDateFrom', from)
      this.$store.commit('dossier/setFilterDateTo', to)
    },

    showChangeDossiersStateModal () {
      this.isChangeDossiersStateModal = true
    },

    hideChangeDossiersStateModal () {
      this.isChangeDossiersStateModal = false
    },
    showChangeInHubModal () {
      this.isChangeInHubModal = true
    },

    hideChangeInHubModal () {
      this.isChangeInHubModal = false
    }
  }
})
