export class ExportController {
  downloadFile (fileContent: string, name: string) {
    const file = new Blob([fileContent])
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.download = name
    link.href = fileURL
    link.click()
    link.remove()
  }
}
