




















import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { DossierService } from '@/network/api/dossier-service'
import { DossierModel } from '@/globals/models/DossierModel'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'

export default Vue.extend({
  components: { InputField, PrimaryButton, Dropdown, PrimaryModal },
  props: {
    _clients: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      dossier: new DossierModel(),
      errors: new DossierModel().getErrorEntity(),
      isClient: this.$store.getters['user/isClient'],
      clientId: null
    }
  },
  watch: {
    clientId () {
      this.dossier.client.id = this.clientId
      this.dossier.client_id = this.clientId
    }
  },
  computed: {
    isSubmitDisabled (): boolean {
      return !this.dossier.license_plate || (!this.isClient && !this.dossier.client.id)
    }
  },
  beforeMount () {
    // @ts-ignore
    delete this.dossier.created_at
    // @ts-ignore
    delete this.dossier.vehicle.id

    if (this.isClient) {
      // @ts-ignore
      delete this.dossier.client
      // @ts-ignore
      delete this.dossier.client_id
    }
  },
  methods: {
    async submitForm () {
      if (!this.isSubmitDisabled) {
        await this.createDossier()
      }
    },
    async createDossier () {
      this.errors = new DossierModel().getErrorEntity()

      await DossierService.createDossier(this.dossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const dossier = new DossierModel().fromResponse(response.data)
          this.$emit('dossier_created', dossier)
        }

        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors.license_plate.push('Dit kenteken bestaat al')
        }
      })
    }
  }
})
