



















import Vue from 'vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import BlankSlate from '@/globals/components/blank-slates/BlankSlate.vue'

export default Vue.extend({
  props: {
    dossiers: {
      type: Array,
      required: true
    }
  },
  components: {
    BlankSlate
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient']
    }
  },
  methods: {
    getDate (date: Date) {
      return DateHelper.toLocaleDateString(date)
    },

    navigateToDossier (id: number) {
      this.$router.push({ name: 'dossier_edit', params: { id: id.toString() } })
    }
  }
})
