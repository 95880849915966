import { client } from '../client'
import { BaseService } from './base-service'

export const KfzService = {
  _apiEndpoint: BaseService.apiEndpoint + 'kfz/',

  getUploadKfzUrl (): string {
    return this._apiEndpoint + 'upload'
  },

  async uploadKfz (data: any) {
    const url = this._apiEndpoint + 'upload'

    return await client.post(url, data)
  },

  async sendKfz (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'send'

    return await client.get(url, { ids: dossierIds.toString() })
  }
}
