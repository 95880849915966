

















import Vue from 'vue'

export default Vue.extend({
  props: {
    _selected: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      choices: [
        {
          value: 0,
          title: 'Adesa auction batch lijst (Excel)',
          subtitle: 'Upload de auction batch lijst',
          disabled: false
        },
        {
          value: 1,
          title: 'Tijdelijk documentnummer(s) uploaden',
          subtitle: 'Upload één of meerdere tijdelijke documentnummers in PDF formaat',
          disabled: false
        },
        {
          value: 2,
          title: 'Uitvoerdocumenten uploaden',
          subtitle: 'Upload één of meerdere uitvoerdocumenten in PDF formaat',
          disabled: false
        },
        {
          value: 3,
          title: 'Kenteken card scannen',
          subtitle: 'Scan een kenteken card en maak daarmee een dossier aan',
          disabled: false
        },
        {
          value: 4,
          title: 'GAIK nummers uploaden',
          subtitle: 'Upload de Excel lijst met GAIK nummers ',
          disabled: false
        },
        {
          value: 5,
          title: 'KFZ-brief uploaden',
          subtitle: 'Upload een batch van KFZ-brieven',
          disabled: false
        },
        {
          value: 6,
          title: 'EG batch uploaden',
          subtitle: 'Upload een batch van EG dossiers',
          disabled: false
        }
      ],
      chosenOption: 0
    }
  },
  computed: {
    isAdesa (): boolean {
      return this.$store.getters['user/isAdesa']
    },
    isClient (): boolean {
      return this.$store.getters['user/isEmployee']
    },
    isEmployee (): boolean {
      return this.$store.getters['user/isEmployee']
    },
    isAdministration (): boolean {
      return this.$store.getters['user/isAdministration']
    },
    filteredChoices (): Array<any> {
      return this.choices.filter((obj) => {
        let valid = true

        if (this.isAdesa) {
          valid = obj.value !== 4 && obj.value !== 5 && obj.value !== 6
        } else if (this.isClient) {
          valid = obj.value !== 0 && obj.value !== 4 && obj.value !== 5 && obj.value !== 6
        } else if (this.isAdministration || this.isEmployee) {
          valid = obj.value !== 0
        }

        return valid
      })
    }
  },
  beforeMount () {
    this.chosenOption = this._selected
  },
  methods: {
    onSelected (value: number) {
      this.chosenOption = value
      this.$emit('selected', value)
    }
  }
})
