











import Vue from 'vue'

export default Vue.extend({
  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
})
