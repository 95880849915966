










import { DossierModel } from '@/globals/models/DossierModel'
import Vue from 'vue'

export default Vue.extend({
  props: {
    dossier: {
      type: DossierModel,
      required: false
    }
  }
})
