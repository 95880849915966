import { LoginModel } from '@/views/login/models/LoginModel'
import { client } from '../client'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { BaseService } from './base-service'

export const AuthService = {
  _apiEndpoint: BaseService.apiBaseEndpoint + 'auth/',

  async login (data: LoginModel) {
    const url = this._apiEndpoint + 'login'

    return await client.post(url, data, true)
  },

  async refresh () {
    const url = this._apiEndpoint + 'refresh'

    const refreshPromise = client.post(url, { token: localStorage.getItem('token') }, true) as Promise<any>
    await refreshPromise.then(async (response: any) => {
      if (response.status === HttpStatus.OK) {
        client.storeToken(response.data.access_token)
      }
    })

    return refreshPromise
  }
}
