




















import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import { DossierStatusEnum } from '@/globals/enums/DossierStatus'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { DossierService } from '@/network/api/dossier-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    PrimaryModal,
    Dropdown,
    PrimaryButton
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      states: new DossierStatusEnum().getAll(),
      selected: null as null|number
    }
  },
  methods: {
    async submitForm () {
      if (this.selected !== null) {
        const ids = [] as Array<number>
        this.items.forEach((item: any) => {
          ids.push(item.id)
        })

        await DossierService.updateDossiersStatus(ids, this.selected).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$emit('status_changed', { dossiers: this.items, status: this.selected })
          }
        })
      }
    }
  }
})
