import { client } from '../client'
import { BaseService } from './base-service'

export const StatsService = {
  _apiEndpoint: BaseService.apiEndpoint + 'stats/',

  async getDashboardStats () {
    const url = this._apiEndpoint

    return await client.get(url)
  }
}
