















































import Vue from 'vue'
import PrimaryButton from '../buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isRange: {
      type: Boolean,
      required: false,
      default: false
    },
    currentDate: {
      required: false
    }
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10) as Array<string>|string,
    menu: false
  }),
  computed: {
    isCorrectRange (): boolean {
      return this.isRange && this.date.length === 2
    }
  },
  beforeMount () {
    if (this.isRange) {
      this.date = []
      if (this.currentDate) {
      } else {
        this.date.push(new Date().toISOString().substr(0, 10))
      }
    }
  },
  methods: {
    handleInput () {
      if (!this.isRange) {
        this.menu = false
        this.$emit('input', this.date)
      }
    }
  }
})
