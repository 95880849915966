













import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { LoginModel } from './models/LoginModel'
import { AuthService } from '@/network/api/auth-service'
import { UserService } from '../../network/api/user-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { UserModel } from '../../globals/models/UserModel'

export default Vue.extend({
  components: {
    InputField,
    PrimaryButton
  },
  data () {
    return {
      login: new LoginModel(),
      errors: new LoginModel().getErrorEntity()
    }
  },
  methods: {
    async submitForm () {
      await AuthService.login(this.login).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          localStorage.setItem('token', response.data.access_token)
          await this.getUser()
          this.navigateToDossiers()
        }
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },
    async getUser () {
      await UserService.getUser().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const user = new UserModel().fromResponse(response.data)
          this.$store.commit('user/setUser', user)
        }
      })
    },
    navigateToDossiers () {
      this.$router.push({ name: 'dossier_overview' })
    },
    clearErrors () {
      this.errors = new LoginModel().getErrorEntity()
    }
  }
})
