import { BaseModel } from '@/globals/models/BaseModel'
import { DateHelper } from '../helpers/DateHelper'

export class ClientModel extends BaseModel {
  id = null as null|number
  name = ''
  last_export_date = null as null|Date
  last_export_end = null as null|Date
  last_export_start = null as null|Date

  constructor (data?: any) {
    super()
    this.setData(data)
  }

  setData (data?: any) {
    if (data) {
      this.id = data.id
      this.name = data.name
      this.last_export_date = data.last_export_date
      this.last_export_end = data.last_export_end
      this.last_export_start = data.last_export_start
    }
  }

  fromResponse (data?: any) {
    this.setData(data)

    this.last_export_date = this.last_export_date ? DateHelper.toDate(data.last_export_date) : null
    this.last_export_end = this.last_export_end ? DateHelper.toDate(data.last_export_end) : null
    this.last_export_start = this.last_export_start ? DateHelper.toDate(data.last_export_start) : null

    return this
  }
}
