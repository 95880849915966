
































































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import DossierStatus from '@/globals/components/dossier-status/DossierStatus.vue'
import { Notify } from '@/globals/controllers/Notification'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DossierStatusEnum } from '@/globals/enums/DossierStatus'
import { DossierService } from '@/network/api/dossier-service'
import { DossierModel } from '@/globals/models/DossierModel'
import { ExportService } from '@/network/api/export-service'
import { ExportController } from '@/globals/controllers/ExportController'
import { LogModel } from '@/globals/models/LogModel'
import { LogService } from '@/network/api/log-service'
import ExportButton from './export-button/ExportButton.vue'
import { LicenseHelper } from '@/globals/helpers/LicenseHelper'
import DossierDanger from '@/views/dossier/components/dossier-danger/DossierDanger.vue'
import { KfzService } from '@/network/api/kfz-service'
import { DossierAttachmentEnum } from '@/globals/enums/DossierAttachment'

export default Vue.extend({
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  components: {
    DossierStatus,
    PrimaryButton,
    ExportButton,
    DossierDanger
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      editDossier: new DossierModel(this._dossier),
      isRemoveConfirmDialog: false,
      dossierStatus: new DossierStatusEnum(),
      attachmentEnum: new DossierAttachmentEnum(),
      isClient: this.$store.getters['user/isClient'],
      isAdministration: this.$store.getters['user/isAdministration'],
      isEmployee: this.$store.getters['user/isEmployee'],
      licenseHelper: LicenseHelper
    }
  },
  watch: {
    _dossier () {
      this.editDossier = new DossierModel(this._dossier)
    }
  },
  methods: {
    async removeDossier () {
      const message = 'Weet je zeker dat je dit dossier wil verwijderen?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isDeleting: true })

      if (isConfirmed) {
        await DossierService.deleteDossier(this._dossier.id).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            new Notify().success('Gelukt!', 'Dossier is verwijderd')
            this.$router.replace({ name: 'dossier_overview' })
          }
        })
      }
    },

    async updateStatus (status: number) {
      this.editDossier.status = status
      await DossierService.updateDossier(this._dossier.id, this.editDossier).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await this.createLog()
        }
      })
    },

    async toggleArrivedAtHub (isArrived: boolean) {
      this.editDossier.arrived_at_hub = isArrived
      await DossierService.toggleArrivedAtHub([this._dossier.id], isArrived)
    },
    async createLog () {
      const log = new LogModel()
      log.create(this.editDossier.id, 'Status gewijzigd naar: ' + this.dossierStatus.getByValue(this.editDossier.status).text)

      await LogService.createLog(log).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const res = new LogModel().fromResponse(response.data)
          this.editDossier.log.push(res)
          this.$emit('updated', this.editDossier)
        }
      })
    },

    async exportDossierPDF () {
      await ExportService.getDossierPDF(this.editDossier.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'export-' + this.editDossier.license_plate + '_' + new Date().toLocaleString() + '.pdf')
        }
      })
    },

    async exportDossierXLS () {
      await ExportService.getDossierExcel(this.editDossier.id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new ExportController().downloadFile(response.data, 'export-' + this.editDossier.license_plate + '_' + new Date().toLocaleString() + '.xlsx')
        }
      })
    },

    async sendKfz () {
      const message = 'Verstuur KFZ brief?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        await KfzService.sendKfz([this.editDossier.id]).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            new Notify().success('Gelukt!', 'KFZ brief is verzonden')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    }
  }
})
