import { BaseModel } from '@/globals/models/BaseModel'
import { DateHelper } from '../helpers/DateHelper'

export class VehicleModel extends BaseModel {
    id = null as null|number
    brand = ''
    model = ''
    date_first_entry = null as null|string
    apk_end_date = null as null|string
    date_foreign_registration = null as null|string
    date_first_release_nl = null as null|string
    approval_type = null as null|string
    cylinder_capacity = null
    foreign_license_plate = null
    fuel = ''
    gaik_one = null as null|string
    gaik_two = null as null|string
    gross_bpm = null
    is_damaged = false
    is_dangerous = false
    judged_mileage = null
    mileage = null as null|number
    net_bpm = null as null|number
    primary_color = ''
    secondary_color = ''
    vin_number = null
    has_wltp = false

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.brand = data.brand
        this.model = data.model
        this.date_first_entry = data.date_first_entry
        this.apk_end_date = data.apk_end_date
        this.date_foreign_registration = data.date_foreign_registration
        this.date_first_release_nl = data.date_first_release_nl
        this.approval_type = data.approval_type
        this.cylinder_capacity = data.cylinder_capacity
        this.foreign_license_plate = data.foreign_license_plate
        this.fuel = data.fuel ?? ''
        this.gaik_one = data.gaik_one
        this.gaik_two = data.gaik_two
        this.gross_bpm = data.gross_bpm
        this.is_damaged = data.is_damaged
        this.is_dangerous = data.is_dangerous
        this.judged_mileage = data.judged_mileage
        this.mileage = data.mileage
        this.net_bpm = data.net_bpm
        this.primary_color = data.primary_color
        this.secondary_color = data.secondary_color
        this.vin_number = data.vin_number
        this.has_wltp = data.has_wltp
      }
    }

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }

    fromResponse (data?: any) {
      if (data) {
        this.setData(data)
        this.date_first_entry = data.date_first_entry ? DateHelper.toDateString(data.date_first_entry) : null
        this.apk_end_date = data.apk_end_date ? DateHelper.toDateString(data.apk_end_date) : null
        this.date_foreign_registration = data.date_foreign_registration ? DateHelper.toDateString(data.date_foreign_registration) : null
        this.date_first_release_nl = data.date_first_release_nl ? DateHelper.toDateString(data.date_first_release_nl) : null
      }
      return this
    }

    isOreh (): boolean {
      return this.net_bpm !== null && this.net_bpm <= 100
    }

    isApkValid (): boolean {
      return this.apk_end_date !== null && DateHelper.toDate(this.apk_end_date) > DateHelper.resetTime(new Date())
    }

    isMilage (): boolean {
      return this.mileage !== null
    }

    isMilageAbove100k (): boolean {
      return this.mileage !== null && this.mileage >= 100000
    }

    isGaiksPresent (): boolean {
      return this.gaik_one !== null && this.gaik_two !== null
    }

    isPetrolCarDanger (): boolean {
      if (this.date_first_entry === null || !this.fuel.toLowerCase().includes('benzine') || this.isApkValidTwoYears()) {
        return false
      }

      const firstEntry = DateHelper.toDate(this.date_first_entry)
      const twoYearsFifyWeeks = new Date()
      twoYearsFifyWeeks.setFullYear(twoYearsFifyWeeks.getFullYear() - 3)
      twoYearsFifyWeeks.setDate(twoYearsFifyWeeks.getDate() + 14)

      const fourYears = new Date()
      fourYears.setFullYear(fourYears.getFullYear() - 4)

      if (firstEntry <= twoYearsFifyWeeks && firstEntry >= fourYears) {
        return true
      }

      return false
    }

    isApkValidTwoYears (): boolean {
      const result = DateHelper.resetTime(new Date())
      result.setDate(1)
      result.setMonth(0)
      result.setFullYear(result.getFullYear() + 2)
      return this.apk_end_date !== null && DateHelper.toDate(this.apk_end_date) > result
    }

    isWltp (): boolean {
      if (this.approval_type === null || this.approval_type.length < 2) {
        return false
      }

      const lastDigits = this.approval_type.slice(-2)
      const wltpChars = [
        'AA',
        'BA',
        'AB',
        'AC',
        'AD',
        'AG',
        'AH',
        'AI',
        'AJ',
        'BB',
        'BC',
        'BG',
        'BH',
        'BI',
        'CG',
        'AE',
        'AF',
        'AK',
        'AL',
        'AM',
        'DG',
        'AN',
        'AO',
        'CH',
        'CI',
        'AX',
        'AY',
        'AZ',
        'AP',
        'AQ',
        'AR'
      ]

      return wltpChars.findIndex((chars) => chars === lastDigits) !== -1
    }
}
