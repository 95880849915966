import { client } from '../client'
import { BaseService } from './base-service'

export const ExportService = {
  _apiEndpoint: BaseService.apiEndpoint + 'export/',

  async getAllDossiersExcel () {
    const url = this._apiEndpoint + 'files'

    return await client.post(url, [], false, { responseType: 'blob' })
  },

  async getDossierPDF (id: number|string) {
    const url = this._apiEndpoint + 'file/' + id + '/pdf'

    return await client.get(url, {}, { responseType: 'blob' })
  },

  async getDossierExcel (id: number|string) {
    const url = this._apiEndpoint + 'file/' + id + '/sheet'

    return await client.get(url, {}, { responseType: 'blob' })
  },

  async getDossiersExcel (ids: Array<number>, saveFilteredDate: boolean) {
    const url = this._apiEndpoint + 'files?savedate=' + saveFilteredDate
    return await client.post(url, ids, false, { responseType: 'blob' })
  },
  async getDossiersBpm (ids: Array<number>) {
    const url = this._apiEndpoint + 'bpmfiles'

    return await client.post(url, ids, false, { responseType: 'blob' })
  },

  async getDossiersZip (ids: Array<number>) {
    const url = this._apiEndpoint + 'files/zip'

    return await client.post(url, ids, false, { responseType: 'blob' })
  }
}
