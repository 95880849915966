




































































import Vue from 'vue'
import { LicenseHelper } from '@/globals/helpers/LicenseHelper'

export default Vue.extend({
  props: {
    label: {
      type: String,
      required: false
    },
    forceLabel: {
      type: Boolean,
      required: false,
      default: false
    },
    labelStyle: {
      type: Object,
      required: false
    },
    inputStyle: {
      type: Object,
      required: false
    },
    height: {
      type: String,
      required: false,
      default: ''
    },
    name: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    icon: {
      type: String,
      required: false
    },
    appendIcon: {
      type: Boolean,
      required: false
    },
    prependIcon: {
      type: Boolean,
      required: false
    },
    prependIconClass: {
      type: String,
      required: false
    },
    step: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      required: false
    },
    max: {
      required: false
    },
    value: {
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    align: {
      type: String,
      required: false,
      default: 'left'
    },
    isPrice: {
      type: Boolean,
      required: false,
      default: false
    },
    showPassword: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    errorModel: {
      type: Array,
      required: false,
      default: () => []
    },
    showLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    isLicensePlate: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    hideErrors: {
      type: Boolean,
      required: false,
      default: false
    },
    isOnlyNumbers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      loadingState: this.$store.state.loading.isLoading as boolean,
      isLoading: false,
      isError: false,
      isPasswordShown: false,
      inputType: 'text'
    }
  },
  computed: {
    isNowLoading (): boolean {
      return this.loadingState && this.showLoading
    },
    isDate (): boolean {
      return this.type === 'date'
    }
  },
  beforeMount () {
    this.inputType = this.type
  },
  methods: {
    handleInput (e: any) {
      if (this.isOnlyNumbers) {
        e.target.value = e.target.value.replace(/\D/g, '')
      }

      if (this.isLicensePlate) {
        e.target.value = LicenseHelper.toLicensePlateStripes(e.target.value)
      }

      this.$emit('input', e.target.value)
    },

    handleBlur (e: any) {
      if (this.isOnlyNumbers) {
        e.target.value = e.target.value.replace(/\D/g, '')
      }
      this.$emit('blur', e.target.value)
    }
  }
})
