




















import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import { LogService } from '@/network/api/log-service'
import { LogModel } from '@/globals/models/LogModel'

export default Vue.extend({
  components: {
    PrimaryButton,
    InputField
  },
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      editDossier: new DossierModel(this._dossier),
      errors: this._dossier.getErrorEntity(),
      dateHelper: DateHelper,
      note: ''
    }
  },
  watch: {
    _dossier () {
      this.setDossierAsEditDossier()
    }
  },
  methods: {
    setDossierAsEditDossier () {
      this.editDossier = new DossierModel(this._dossier)
    },

    clearErrors () {
      this.errors = this._dossier.getErrorEntity()
    },

    async createLog () {
      const log = new LogModel()
      log.file_id = this.editDossier.id
      log.message = this.note
      await LogService.createLog(log).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const res = new LogModel().fromResponse(response.data)
          this.editDossier.log.push(res)
          this.note = ''
          this.$emit('updated', this.editDossier)
        }
      })
    },

    async updateDossier () {
      this.clearErrors()
      await DossierService.updateDossier(this._dossier.id, this.editDossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.setIsEditing(false)
          this.$emit('updated', new DossierModel().fromResponse(response.data))
        }

        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },

    setIsEditing (isEditing: boolean) {
      this.isEditing = isEditing
    }
  }
})
