















































































import Vue from 'vue'

import FileUpload from 'vue-upload-component'
import { DossierService } from '@/network/api/dossier-service'
import { AuthService } from '@/network/api/auth-service'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'
import Dropdown from '@/globals/components/form/Dropdown.vue'

export default Vue.extend({
  components: {
    FileUpload,
    PrimaryButton,
    Dropdown
  },
  props: {
    _clients: {
      type: Array,
      required: false
    },
    isAdesaBatch: {
      type: Boolean,
      required: false,
      default: false
    },
    isTemporaryDocument: {
      type: Boolean,
      required: false,
      default: false
    },
    isDocument2: {
      type: Boolean,
      required: false,
      default: false
    },
    isLicenseCard: {
      type: Boolean,
      required: false,
      default: false
    },
    isGaik: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      clientId: null,
      uploadCompleted: false,
      files: [],
      accept: 'pdf',
      // extensions: 'pdf,gif,jpg,jpeg,png,webp',
      extensions: ['pdf'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 0,
      size: 1024 * 1024 * 10,
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      addIndex: false,
      thread: 3,
      name: 'file',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      }
    }
  },
  watch: {
    clientId () {
      this.setReqData()
    }
  },
  computed: {
    uploadUrl (): string {
      let url = DossierService.getUploadMultiDossiersByTemporaryDocumentUrl()

      if (this.isLicenseCard) {
        url = DossierService.getUploadMultiDossiersByLicenseCardUrl()
      } else if (this.isDocument2) {
        url = DossierService.getUploadMultiDossiersByDocument2Url()
      } else if (this.isAdesaBatch) {
        url = DossierService.getUploadMultiDossiersByAdesaUrl()
      } else if (this.isGaik) {
        url = DossierService.getUploadMultiDossiersByGaikUrl()
      }

      if (this.isClient) {
        return url
      } else {
        return url + '?clientId=' + this.$data.clientId
      }
    },

    reqBody (): object {
      if (this.isClient) {
        return { clientId: this.clientId }
      } else {
        return {}
      }
    },

    headersBody (): object {
      return {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    },

    isSubmitDisabled (): boolean {
      return (!this.isClient && !this.clientId)
    }
  },
  beforeMount () {
    if (this.isAdesaBatch || this.isGaik) {
      this.accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      this.extensions = ['xlsx']
    }
    if (this.isLicenseCard) {
      this.accept = 'csv'
      this.extensions = ['csv']
    }
  },
  methods: {
    isNoFiles (): boolean {
      return this.files.length === 0
    },
    isUploading (): boolean {
      // eslint-disable-next-line
      // @ts-ignore
      return this.$refs.upload && this.$refs.upload.active
    },
    isUploadComplete (): boolean {
      // eslint-disable-next-line
      // @ts-ignore
      return this.uploadCompleted && this.$refs.upload && this.$refs.upload.uploaded
    },
    onAddData () {
      this.addData.show = false
      // eslint-disable-next-line
      // @ts-ignore
      if (!this.$refs.upload.features.html5) {
        alert('Your browser does not support HTML5')
        return
      }
      const file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      })
      // eslint-disable-next-line
      // @ts-ignore
      this.$refs.upload.add(file)
    },
    isAllUploadedErrors (): boolean {
      let error = true

      this.files.forEach((file: any) => {
        if (file.success) {
          error = false
        }
      })

      return error
    },

    async startUpload () {
      await AuthService.refresh()
      this.resetAllFiles()
      const upload = this.$refs.upload as any
      upload.active = true
      this.uploadCompleted = true
    },

    resetAllFiles () {
      this.files.forEach((file: any) => {
        this.resetFile(file)
      })
    },

    resetFile (file: any) {
      const upload = this.$refs.upload as any
      upload.update(file, { active: false, error: '', progress: '00.00', data: this.reqBody, headers: this.headersBody })
    },

    setReqData () {
      this.files.forEach((file: any) => {
        const upload = this.$refs.upload as any
        upload.update(file, { postAction: this.uploadUrl, data: this.reqBody })
      })
    },

    retryFile (file: any) {
      const upload = this.$refs.upload as any
      this.resetFile(file)
      upload.active = true
    },

    onDone () {
      if (this.isGaik) {
        const unknownLicences = this.onPostUploadGaik()

        if (unknownLicences.length) {
          this.$emit('upload_gaik_complete', { unknownLicences: unknownLicences, clientId: this.$data.clientId })
        } else {
          this.$emit('upload_complete')
        }
      } else {
        this.handleSuccessCount()
        this.$emit('upload_complete')
      }
    },
    onPostUploadGaik () {
      const unknownLicenses = [] as Array<any>

      this.files.forEach((file: any) => {
        if (file.success) {
          if (Array.isArray(file.response)) {
            file.response.forEach((element: any) => {
              unknownLicenses.push(element)
            })
          }
        }
      })

      return unknownLicenses
    },
    handleSuccessCount () {
      const count = this.getSuccessCount()

      if (count != null) {
        new Notify().success(
          this.numberOfNewFilesMessage(count.created) +
          this.numberOfUpdatedFilesMessage(count.updated),
          'Je kunt het aanvullen of dubbelchecken')
      }
    },
    getSuccessCount (): null|{ created: number; updated: number } {
      let created = 0
      let updated = 0

      this.files.forEach((file: any) => {
        if (file.success) {
          if (file.response.created_at === file.response.updated_at) {
            created += 1
          }
          if (file.response.created_at !== file.response.updated_at) {
            updated += 1
          }
        }
      })

      return { created: created, updated: updated }
    },
    numberOfNewFilesMessage (count: number) {
      if (count === 1) {
        return `Er is ${count} nieuw dossier aangemaakt <br />`
      } else if (count > 1) {
        return `Er zijn ${count} nieuwe dossiers aangemaakt <br />`
      } else {
        return ''
      }
    },
    numberOfUpdatedFilesMessage (count: number) {
      if (count === 1) {
        return `Er is ${count} dossier geüpdate`
      } else if (count > 1) {
        return `Er zijn ${count} dossiers geüpdate`
      } else {
        return ''
      }
    }
  }
})
