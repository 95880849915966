import { client } from '../client'
import { BaseService } from './base-service'

export const ClientService = {
  _apiEndpoint: BaseService.apiEndpoint + 'client/',

  async getClients () {
    return await client.get(this._apiEndpoint)
  }
}
