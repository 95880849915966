























import Vue from 'vue'
import PrimaryButton from './globals/components/buttons/PrimaryButton.vue'
import Navbar from './globals/components/navbar/Navbar.vue'
import update from '@/globals/mixins/update'
import ConfirmDialog from './globals/components/confirm-dialog/ConfirmDialog.vue'

export default Vue.extend({
  components: { Navbar, PrimaryButton, ConfirmDialog },
  mixins: [update],
  data () {
    return {
      dialogStore: this.$store.state.dialog
    }
  },
  computed: {
    isLoginRoute (): boolean {
      return this.$route.name === 'login'
    }
  },
  mounted () {
    this.dialogStore.confirmDialog = this.$refs.dialog
  }
})
