













































































































































import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { DossierAttachmentEnum } from '@/globals/enums/DossierAttachment'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import Vue from 'vue'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      editDossier: new DossierModel(this._dossier),
      errors: this._dossier.getErrorEntity(),
      attachmentEnum: new DossierAttachmentEnum(),
      selectedAttachmentType: 0
    }
  },
  watch: {
    _dossier () {
      this.setDossierAsEditDossier()
    }
  },
  computed: {
    tempDocAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.TemporaryDocument.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    DatenblattAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.Datenblatt.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    licenseCardAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.LicenseCard.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    kfzLetterAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.KfzLetter.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    exportAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.Export.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    prufungszertifikat (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.Prufungszertifikat.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    egFile (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.EgFile.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    },
    additionalAttachment (): Array<object> {
      return this.editDossier.getAttachmentsByType(this.attachmentEnum.Additional.value)
    }
  },
  methods: {
    setDossierAsEditDossier () {
      this.editDossier = new DossierModel(this._dossier)
    },

    clearErrors () {
      this.errors = this._dossier.getErrorEntity()
    },

    selectDocument (attachmentType: number, accept?: string) {
      this.selectedAttachmentType = attachmentType
      const element = this.$refs.uploadDocument as any

      element.accept = accept

      element.click()
    },

    async onSelectedFile (e: any) {
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        await this.uploadDocument(file)
      }

      e.target.value = null
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadDocument (file: any) {
      const formData = new FormData()
      formData.append('file', file)
      await DossierService.uploadDossierAttachment(this.editDossier.id, this.selectedAttachmentType, formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const dossier = new DossierModel().fromResponse(response.data)

          if (this.selectedAttachmentType === this.attachmentEnum.KfzLetter.value) {
            this.updateDossier(dossier)
          } else {
            this.$emit('updated', dossier)
          }
        }
      })
    },

    async removeAttachment (id: number) {
      await DossierService.deleteDossierAttachment(this.editDossier.id, id).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.editDossier.removeAttachment(id)
          this.$emit('updated', this.editDossier)
        }
      })
    },

    openDocument (url: string) {
      window.open(url, '_blank')
    },

    async updateDossier (dossier: DossierModel) {
      this.clearErrors()
      await DossierService.updateDossier(dossier.id, dossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.setIsEditing(false)
          this.$emit('updated', new DossierModel().fromResponse(response.data))
        }

        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },

    setIsEditing (isEditing: boolean) {
      this.isEditing = isEditing
    }
  }
})
