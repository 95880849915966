import { BaseModel } from '@/globals/models/BaseModel'
import { VehicleModel } from './VehicleModel'
import { DateHelper } from '../helpers/DateHelper'
import { LogModel } from './LogModel'
import { AttachmentModel } from './AttachmentModel'
import { ClientModel } from './ClientModel'
import { DossierAttachmentEnum } from '../enums/DossierAttachment'
import { LicenseHelper } from '../helpers/LicenseHelper'
export class DossierModel extends BaseModel {
    id = 0
    file_number = null as null|string
    license_plate = null as null|string
    car_id = null as null|number
    ascription_code_part_one = null
    ascription_code_part_two = null
    status = 0
    client_id = null as null|number
    vehicle_id = null as null|number
    date_export = null as null|Date|string
    arrived_at_hub = false
    created_at = new Date()
    updated_at = new Date()
    client = new ClientModel()
    vehicle = new VehicleModel() as VehicleModel
    attachments = [] as Array<AttachmentModel>
    log = [] as Array<LogModel>

    constructor (data?: any) {
      super()
      this.setData(data)
    }

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.file_number = data.file_number
        this.license_plate = data.license_plate
        this.car_id = data.car_id
        this.ascription_code_part_one = data.ascription_code_part_one
        this.ascription_code_part_two = data.ascription_code_part_two
        this.status = data.status
        this.client_id = data.client_id
        this.vehicle_id = data.vehicle_id
        this.date_export = data.date_export
        this.arrived_at_hub = data.arrived_at_hub
        this.created_at = data.created_at
        this.updated_at = data.updated_at
        this.client = data.client
        this.vehicle = data.vehicle
        this.attachments = data.attachments
        this.log = data.log
      }
    }

    getErrorEntity () {
      const errorModel = super.generateErrorEntity(this) as any
      errorModel.vehicle = this.vehicle.generateErrorEntity(this.vehicle)

      return errorModel
    }

    fromResponse (data: any) {
      this.setData(data)

      this.date_export = data.date_export ? DateHelper.toDateString(data.date_export) : null
      this.created_at = new Date(data.created_at)
      this.updated_at = new Date(data.updated_at)

      for (let i = 0; i < this.attachments.length; i++) {
        this.attachments[i] = new AttachmentModel().fromResponse(this.attachments[i])
      }

      for (let i = 0; i < this.log.length; i++) {
        this.log[i] = new LogModel().fromResponse(this.log[i])
      }

      this.client = new ClientModel().fromResponse(data.client)
      this.vehicle = new VehicleModel().fromResponse(data.vehicle)

      return this
    }

    isTemporaryDocumentComplete (): boolean {
      return this.license_plate !== null && this.file_number !== null && this.ascription_code_part_one !== null && this.ascription_code_part_two !== null
    }

    isAttachmentUploaded (type: number): boolean {
      let isUploaded = false

      this.attachments.forEach((attachment: AttachmentModel) => {
        if (attachment.type === type) {
          isUploaded = true
        }
      })

      return isUploaded
    }

    getAttachmentsByType (type: number): Array<AttachmentModel> {
      const attachments = [] as Array<AttachmentModel>

      this.attachments.forEach((attachment) => {
        if (attachment.type === type) {
          attachments.push(attachment)
        }
      })

      return attachments
    }

    removeAttachment (id: number) {
      for (let i = 0; i < this.attachments.length; i++) {
        if (this.attachments[i].id === id) {
          this.attachments.splice(i, 1)
        }
      }
    }

    isComplete (): boolean {
      if (this.vehicle.isOreh()) {
        return this.isCompleteOreh()
      }

      const attachment = this.isAttachmentUploaded(new DossierAttachmentEnum().TemporaryDocument.value)
      const hasDatenblatt = this.isAttachmentUploaded(new DossierAttachmentEnum().Datenblatt.value)
      if (
        this.file_number !== null && this.vehicle.mileage && this.vehicle.vin_number && hasDatenblatt && attachment && this.arrived_at_hub && this.vehicle.isApkValid() && this.vehicle.isGaiksPresent() && this.vehicle.approval_type) {
        return true
      } else {
        return false
      }
    }

    isCompleteOreh (): boolean {
      const isAttachment = this.isAttachmentUploaded(new DossierAttachmentEnum().LicenseCard.value)
      return this.file_number !== null && this.vehicle.isGaiksPresent() && this.vehicle.isMilage() && isAttachment
    }

    isCompleteAdesa (): boolean {
      const attachment = this.isAttachmentUploaded(new DossierAttachmentEnum().TemporaryDocument.value)
      if (
        this.file_number !== null && this.vehicle.mileage && this.vehicle.vin_number && attachment && this.arrived_at_hub) {
        return true
      } else {
        return false
      }
    }

    getLicensePlateStriped (): string {
      return LicenseHelper.toLicensePlateStripes(this.license_plate)
    }

    hasMissingData (isAdesa: boolean): any {
      let missing = {}
      if (isAdesa && !this.isCompleteAdesa()) {
        missing = {
          file_number: !this.file_number,
          mileage: !this.vehicle.mileage,
          vin_number: !this.vehicle.vin_number,
          attachment: !this.isAttachmentUploaded(new DossierAttachmentEnum().TemporaryDocument.value),
          arrived_at_hub: !this.arrived_at_hub
        }
      }

      if (!isAdesa && !this.vehicle.isOreh() && !this.isComplete()) {
        const hasDatenblatt = this.isAttachmentUploaded(new DossierAttachmentEnum().Datenblatt.value)
        missing = {
          mileage: !this.vehicle.mileage,
          vin_number: !this.vehicle.vin_number,
          attachment: !this.isAttachmentUploaded(new DossierAttachmentEnum().TemporaryDocument.value),
          arrived_at_hub: !this.arrived_at_hub,
          is_apk_valid: !this.vehicle.isApkValid(),
          has_gaik: !this.vehicle.isGaiksPresent(),
          has_approval_type: !this.vehicle.approval_type,
          has_datenblatt: !hasDatenblatt
        }
      }
      if (!isAdesa && this.vehicle.isOreh() && !this.isCompleteOreh()) {
        const isLicenseCard = this.isAttachmentUploaded(new DossierAttachmentEnum().LicenseCard.value)
        missing = {
          file_number: !this.file_number,
          mileage: !this.vehicle.mileage,
          arrived_at_hub: !this.arrived_at_hub,
          has_gaik: !this.vehicle.isGaiksPresent(),
          has_license_card: !isLicenseCard
        }
      }
      return missing
    }
}
