import { client } from '../client'
import { BaseService } from './base-service'

export const UserService = {
  _apiEndpoint: BaseService.apiEndpoint + 'user/',

  async getUser () {
    const url = this._apiEndpoint

    return await client.get(url)
  }
}
