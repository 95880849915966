import { BaseModel } from '@/globals/models/BaseModel'
import { UserModel } from './UserModel'

export class LogModel extends BaseModel {
    file_id = null as null|number
    message = ''
    date = null as null|Date
    user = null as null|UserModel

    setData (data?: any) {
      if (data) {
        this.file_id = data.file_id
        this.message = data.message
        this.date = new Date(data.created_at)
        this.user = new UserModel().fromResponse(data.user)
      }
    }

    create (file_id: number, message: string) {
      this.file_id = file_id
      this.message = message
    }

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }
}
