<template>
  <div class="dashboard__counter">
    <h3 class="subtitle">{{ title }}</h3>
    <span class="counter--count">{{ count }}</span>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      count: 0,
      animationDuration: 1000,
      frameDuration: 1000 / 60,
      easeOutQuad: t => t * (2 - t)
    }
  },
  watch: {
    totalCount () {
      this.countUp()
    }
  },
  beforeMount () {
    // this.countUp()
  },
  methods: {
    countUp () {
      const totalFrames = Math.round(this.animationDuration / this.frameDuration)
      let frame = 0
      const countTo = parseInt(this.totalCount, 10)
      const counter = setInterval(() => {
        frame++
        const progress = this.easeOutQuad(frame / totalFrames)
        const currentCount = Math.round(countTo * progress)
        this.count = currentCount

        if (frame === totalFrames) {
          clearInterval(counter)
        }
      }, this.frameDuration)
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard__counter {
  h3 {
    margin-bottom: 15px;
  }
  >.counter--count {
    font-size: 32px;
    font-weight: 600;
  }
}
</style>
