function setDossierFilter (filter: any) {
  localStorage.setItem('dossier_filter', JSON.stringify(filter))
}

function getClearDossierFilter () {
  return { client: null, status: null, oreh: null, others: null, date: null, from: null, to: null }
}

// initial state
const state = () => ({
  isBpmCalculatorModalActive: false,
  filter: localStorage.getItem('dossier_filter') ? JSON.parse(localStorage.getItem('dossier_filter') as string) : getClearDossierFilter()
})

// getters
const getters = {
  isBpmCalculatorModalActive: (state: any) => {
    return state.isBpmCalculatorModalActive
  }
}

// actions
const actions = {
  setIsBpmCalculatorModalActive ({ commit }: any, active: boolean) {
    commit('setisBpmCalculatorModalActive', active)
  }
}

// mutations
const mutations = {
  setIsBpmCalculatorModalActive (state: any, active: boolean) {
    state.isBpmCalculatorModalActive = active
  },
  setFilterClient (state: any, client: number|null) {
    const filter = state.filter
    filter.client = client
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterStatus (state: any, status: number|null) {
    const filter = state.filter
    filter.status = status
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterOreh (state: any, oreh: boolean|null) {
    const filter = state.filter
    filter.oreh = oreh
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterOthers (state: any, others: Array<string>|null) {
    const filter = state.filter
    filter.others = others
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDate (state: any, date: number|null) {
    const filter = state.filter
    filter.date = date
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDateFrom (state: any, from: number|null) {
    const filter = state.filter
    filter.from = from
    setDossierFilter(filter)
    state.filter = filter
  },
  setFilterDateTo (state: any, to: number|null) {
    const filter = state.filter
    filter.to = to
    setDossierFilter(filter)
    state.filter = filter
  },
  unsetFilter (state: any) {
    state.filter = getClearDossierFilter()
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
