
































































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: ['enableToggle'],
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      isAdministration: this.$store.getters['user/isAdministration'],
      isEmployee: this.$store.getters['user/isEmployee']
    }
  }
})
