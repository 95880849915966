export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  UPDATED = 202,
  DELETED = 204,
  UNPROCESSABLE_ENTITY = 422,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  SERVER_ERROR = 500,
}
