










































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { KfzService } from '@/network/api/kfz-service'
import { EgService } from '@/network/api/eg-service'
import { AuthService } from '@/network/api/auth-service'

export default Vue.extend({
  components: {
    PrimaryButton
  },
  props: {
    isKfz: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      file: null as any,
      isUploading: false,
      isUploadDone: false,
      isUploadError: false,
      progress: 0,
      currentPage: 0,
      totalPages: 0,
      progresses: [] as Array<{ isSuccess: boolean; message: string; pages: string }>
    }
  },
  watch: {
  },
  computed: {
    isError (): boolean {
      return this.progress === 100 && this.isUploadError
    },
    isSuccess (): boolean {
      return this.progress === 100 && !this.isUploadError
    },
    progressBarColor (): string {
      let color = 'var(--button-color-blue)'

      if (this.isError) {
        color = 'var(--state-color-error)'
      }
      if (this.isSuccess) {
        color = 'var(--state-color-success)'
      }

      return color
    },
    uploadUrl (): string {
      return this.isKfz ? KfzService.getUploadKfzUrl() : EgService.getUploadEgUrl()
    }
  },

  methods: {
    selectDocument () {
      const element = this.$refs.uploadKfzDocument as any
      element.click()
    },
    async onSelectedFile (e: any) {
      this.file = e.target.files[0]
      this.progress = 0
    },

    async uploadDocument () {
      const formData = new FormData()
      formData.append('file', this.file)

      const headers = {
        'Transfer-Encoding': 'chunked',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }

      const call = async () => {
        const response = await fetch(this.uploadUrl, {
          method: 'POST',
          headers: headers,
          body: formData
        })

        if (response.body !== null) {
          const streamReader = response.body.getReader()
          let responseCount = 0

          const stream = async () => {
            await streamReader.read().then(async ({ done, value }) => {
              if (done) {
                this.progress = 100
                this.currentPage = this.totalPages
                return
              } else {
                const val = new TextDecoder().decode(value, { stream: true })
                const valObjString = val.substring(
                  val.lastIndexOf('{'),
                  val.lastIndexOf('}') + 1
                )
                const jsonValue = JSON.parse(valObjString)

                this.handleProgressEvents(responseCount === 0, jsonValue)
                responseCount = responseCount + 1
              }

              // Read some more, and call this function again
              return await stream()
            })
          }

          await stream()
        }
      }

      await AuthService.refresh()
      this.isUploading = true
      try {
        await call()
      } catch (error) {
        this.isUploadError = true
      }
      this.isUploadDone = true
    },
    handleProgressEvents (isFirstResponse: boolean, response: { result: boolean; vin_code: string; pages: Array<number> }) {
      if (isFirstResponse) {
        this.totalPages = parseInt(response.vin_code)
      } else {
        this.currentPage = response.pages[response.pages.length - 1]
        this.progress = Math.round(this.currentPage * 100 / this.totalPages)
        this.progresses.push({ isSuccess: response.result, message: response.vin_code, pages: response.pages.toString() })
      }
    }
  }
})
