

























































import Vue from 'vue'
import InputField from '@/globals/components/form/InputField.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { ClientModel } from '@/globals/models/ClientModel'

export default Vue.extend({
  components: {
    InputField,
    DatePicker
  },
  props: {
    _selected: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    dates: {
      type: Array,
      required: false
    },
    client: {
      type: ClientModel,
      required: false
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      selections: [
        { value: 0, text: 'Alle periodes' },
        { value: 1, text: 'Laatste 7 dagen' },
        { value: 2, text: 'Laatste 30 dagen' },
        { value: 3, text: 'Laatste 3 maanden' },
        { value: 4, text: 'Sinds laatste export tot nu' },
        { value: 5, text: '' }
      ],
      selected: 2,
      isMenuActive: false,
      rangePeriod: null as null|string,
      dateHelper: DateHelper,
      lastExportDate: '',
      lastExportFrom: '',
      lastExportTo: ''
    }
  },
  watch: {
    client: {
      handler: function () {
        this.setClientDates()
      },
      deep: true
    }
  },
  beforeMount () {
    this.selected = this._selected
    this.setClientDates()

    if (this.selected === 0 || this.selected === 1) {
      this.selectSevenDays()
    } else if (this.selected === 2) {
      this.selectThirtyDays()
    } else if (this.selected === 3) {
      this.selectThreemonths()
    } else if (this.selected === 4) {
      this.selectFromLastExport()
    }

    const dates = this.dates as Array<Date>
    if (this.selected === 5 && dates) {
      this.selections[5].text = this.dateHelper.toLocaleDateNums(dates[0]) + ' — ' + this.dateHelper.toLocaleDateNums(dates[1])
    }
  },
  methods: {
    setClientDates () {
      if (this.client) {
        if (this.client.last_export_date) {
          this.lastExportDate = DateHelper.toLocaleDateNums(this.client.last_export_date)
        }

        if (this.client.last_export_start) {
          this.lastExportFrom = DateHelper.toLocaleDateNums(this.client.last_export_start)
        }

        if (this.client.last_export_end) {
          this.lastExportTo = DateHelper.toLocaleDateNums(this.client.last_export_end)
        }
      }
    },

    selectAllDays () {
      this.selected = 0
      this.isMenuActive = false
      this.$emit('selected_all')
    },
    selectSevenDays () {
      const to = this.today()
      const from = this.today()
      from.setDate(from.getDate() - 7)
      this.selected = 1
      this.returnDates([from, to])
    },
    selectThirtyDays () {
      const to = this.today()
      const from = this.today()
      from.setDate(from.getDate() - 30)
      this.selected = 2
      this.returnDates([from, to])
    },
    selectThreemonths () {
      const to = this.today()
      const from = this.today()
      from.setMonth(from.getMonth() - 3)
      this.selected = 3
      this.returnDates([from, to])
    },
    selectFromLastExport () {
      if (this.client && this.client.last_export_date) {
        let from = this.client.last_export_date
        from = DateHelper.resetTime(from)
        const to = this.today()
        this.selected = 4
        this.returnDates([from, to])
      }
    },
    selectRange (range: Array<string>) {
      let from = new Date(range[0])
      let to = new Date(range[1])
      this.selected = 5

      if (to < from) {
        const oldFrom = from
        from = to
        to = oldFrom
      }

      from = DateHelper.resetTime(from)
      to = DateHelper.resetTime(to)

      this.selections[5].text = this.dateHelper.toLocaleDateNums(from) + ' — ' + this.dateHelper.toLocaleDateNums(to)
      this.returnDates([from, to])
    },
    today (): Date {
      let date = new Date()
      date = DateHelper.resetTime(date)

      return date
    },
    returnDates (dates: Array<Date|null>) {
      this.isMenuActive = false
      this.$emit('selected', { dates: dates, selected: this.selected })
    }
  }
})
