import { BaseModel } from '@/globals/models/BaseModel'
import { RoleEnum } from '../enums/RoleEnum'

export class UserModel extends BaseModel {
    id = null
    email = ''
    first_name = ''
    last_name = ''
    role_id = RoleEnum.Employee
    client_id = 0

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.email = data.email
        this.first_name = data.first_name
        this.last_name = data.last_name
        this.role_id = data.role_id
        this.client_id = data.client_id
      }
    }

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }
}
