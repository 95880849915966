







































import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DossierService } from '@/network/api/dossier-service'
import DossiersTable from './components/dossiers-table/DossiersTable.vue'
import { DossierModel } from '@/globals/models/DossierModel'
import NewDossierStepper from './components/new-dossier-stepper/NewDossierStepper.vue'
import { ClientService } from '@/network/api/client-service'
import { ClientModel } from '@/globals/models/ClientModel'
import { Notify } from '@/globals/controllers/Notification'
import CreateFileManually from './components/create-file-manually/CreateFileManually.vue'
import { DossierStatusEnum } from '@/globals/enums/DossierStatus'
import { DatenblattService } from '../../../network/api/datenblatt-service'
import { KfzService } from '@/network/api/kfz-service'

export default Vue.extend({
  components: {
    DossiersTable,
    NewDossierStepper,
    CreateFileManually
  },
  data () {
    return {
      dialogStore: this.$store.state.dialog,
      isClient: this.$store.getters['user/isClient'],
      isEssentialsLoaded: false,
      isDossiersLoading: false,
      dossiers: [] as Array<DossierModel>,
      clients: [] as Array<ClientModel>,
      dossierStatus: new DossierStatusEnum(),
      isNewDossierModal: false,
      isNewFileManualModal: false,
      gaikUnknownLicenses: { unknownLicences: [] as Array<{ license: string; gaik_one: string; gaik_two: string }>, clientId: null as null|number }
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      this.dossiers.length = 0
      if (!this.isClient) {
        await this.getClients()
      }
      this.isEssentialsLoaded = true
    },

    async getDossiers (dates: Array<any>|null) {
      this.isDossiersLoading = true
      this.dossiers.splice(0, this.dossiers.length)
      await DossierService.getDossiers(dates !== null ? dates[0] : null, dates !== null ? dates[1] : null).then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await response.data.forEach((dossierRaw: any) => {
            const dossier = new DossierModel().fromResponse(dossierRaw)
            this.dossiers.push(dossier)
          })
        }
      })
      this.isDossiersLoading = false
    },

    async getClients () {
      await ClientService.getClients().then(async (response: any) => {
        if (response.status === HttpStatus.OK) {
          await response.data.forEach((clientRaw: any) => {
            const client = new ClientModel().fromResponse(clientRaw)
            this.clients.push(client)
          })
        }
      })
    },

    async deleteDossiers (items: Array<{id: number}>) {
      const message = 'Je staat op het punt om ' + items.length + ' dossier(s)ss te verwijderen'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isDeleting: true, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DossierService.deleteDossiers(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            items.forEach((item) => {
              const index = this.dossiers.findIndex(d => d.id === item.id)
              if (index !== null) {
                this.dossiers.splice(index, 1)
              }
            })
            new Notify().success('Gelukt!', items.length + ' dossiers' + (items.length > 1 ? 's' : '') + ' verwijderd')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async requestApk (items: Array<{id: number}>) {
      const message = 'APK aanvragen voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DossierService.requestApk(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.status = this.dossierStatus.ApkRequested.value
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'APK voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' aangevraagd')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async createDossiersWithGaik (e: any) {
      this.gaikUnknownLicenses = e
      if (this.gaikUnknownLicenses.unknownLicences.length) {
        const message = 'De volgende ' + this.gaikUnknownLicenses.unknownLicences.length + ' dossier(s) zijn nog niet aangemaakt'
        const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, agreeButtonText: 'Aanmaken', isManual: true })

        if (isConfirmed) {
          await this.gaikUnknownLicenses.unknownLicences.forEach(async data => {
            const dossier = new DossierModel()
            dossier.license_plate = data.license
            dossier.vehicle.gaik_one = data.gaik_one
            dossier.vehicle.gaik_two = data.gaik_two
            await this.createDossier(dossier)
          })
          new Notify().success('Gelukt!', this.gaikUnknownLicenses.unknownLicences.length + ' dossier(s) aangemaakt')
        }
        this.dialogStore.confirmDialog.close()
        this.gaikUnknownLicenses.unknownLicences = []
      }
    },
    async createDossier (dossier: DossierModel) {
      // @ts-ignore
      delete dossier.created_at
      // @ts-ignore
      delete dossier.vehicle.id

      if (this.isClient) {
        // @ts-ignore
        delete dossier.client
        // @ts-ignore
        delete dossier.client_id
      } else {
        dossier.client.id = this.gaikUnknownLicenses.clientId
        dossier.client_id = this.gaikUnknownLicenses.clientId
      }
      await DossierService.createDossier(dossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const dossierRes = new DossierModel().fromResponse(response.data)
          this.dossiers.push(dossierRes)
        }
      })
    },

    async requestGas3to4Years (items: Array<{id: number}>) {
      const message = 'Benzine 3 tot 4 jaar aanvragen voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DossierService.requestGas3to4Years(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.status = this.dossierStatus.Gas3to4YearsRequested.value
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'Benzine 3 tot 4 jaar voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' aangevraagd')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async requestAssignmentAucotras (items: Array<{id: number; dossier: DossierModel}>) {
      const dossiers = [] as Array<DossierModel>

      items.forEach(item => {
        if (!item.dossier.vehicle.isPetrolCarDanger()) {
          dossiers.push(item.dossier)
        }
      })

      const message = 'Zet opdracht uit bij Aucotras (Adesa/Peine) voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(dossiers)
        await DossierService.requestAssignmentAucotras(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.updated_at = new Date()
                dossier.status = this.dossierStatus.WaitingFueltypeGas.value
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'Opdracht is uitgezet bij Aucotras (Adesa/Peine) voor ' + ids.length + ' dossier' + (ids.length > 1 ? 's' : ''))
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async requestCompleteDatenblattAchim (items: Array<{id: number}>) {
      const message = 'Compleet datenblatt aanvragen bij Achim voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DatenblattService.requestCompleteDatenblattAchim(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'Compleet datenblatt is voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' aangevraagd bij Achim')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async requestTiresizeDatenblattAchim (items: Array<{id: number}>) {
      const message = 'Bandenmaat datenblatt aanvragen bij Achim voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DatenblattService.requestTiresizeDatenblattAchim(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'Bandenmaat datenblatt is voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' aangevraagd bij Achim')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async sendWltp (items: Array<{id: number}>) {
      const message = 'Verstuur WLTP auto\'s e-mail voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DatenblattService.sendWltp(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'E-mail voor WLTP auto\'s is voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' verstuurd')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async sendMissingApprovalType (items: Array<{id: number}>) {
      const message = 'Verstuur ontbrekend type-goedkeuringsnummer auto\'s e-mail voor ' + items.length + ' dossier(s)?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await DossierService.sendMissingApprovalType(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            ids.forEach(id => {
              const index = this.findDossierIndex(id)
              if (index >= 0) {
                const dossier = this.dossiers[index]
                dossier.status = this.dossierStatus.WaitingTypeApprovalMissing.value
                dossier.updated_at = new Date()
                this.dossiers.splice(index, 1, dossier)
              }
            })
            new Notify().success('Gelukt!', 'E-mail voor ontbrekend type-goedkeuringsnummer auto\'s is voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + ' verstuurd')
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    async sendKfz (items: Array<{id: number}>) {
      const message = 'Verstuur KFZ brief voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : '') + '?'
      const isConfirmed = await this.dialogStore.confirmDialog.open({ message: message, isManual: true })

      if (isConfirmed) {
        const ids = this.getExtractedIds(items)
        await KfzService.sendKfz(ids).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            new Notify().success('Gelukt!', 'KFZ brief is verzonden voor ' + items.length + ' dossier' + (items.length > 1 ? 's' : ''))
          }
        })
      }
      this.dialogStore.confirmDialog.close()
    },

    getExtractedIds (items: Array<{ id: number }>): Array<number> {
      const ids = [] as Array<number>
      items.forEach(item => {
        ids.push(item.id)
      })

      return ids
    },

    updateDossiersStatus (data: { dossiers: Array<{id: number}>; status: number }) {
      data.dossiers.forEach((dossier) => {
        const index = this.findDossierIndex(dossier.id)
        if (index >= 0) {
          const dossier = this.dossiers[index]
          dossier.status = data.status
          dossier.updated_at = new Date()
          this.dossiers.splice(index, 1, dossier)
        }
      })

      new Notify().success('Gelukt!', 'Status voor ' + data.dossiers.length + ' dossier' + (data.dossiers.length > 1 ? 's' : '') + ' gewijzigd')
    },

    updateArrivedInHub (data: { dossiers: Array<{id: number}>; inHub: boolean }) {
      data.dossiers.forEach((dossier) => {
        const index = this.findDossierIndex(dossier.id)
        if (index >= 0) {
          const dossier = this.dossiers[index]
          dossier.arrived_at_hub = data.inHub
          dossier.updated_at = new Date()
          this.dossiers.splice(index, 1, dossier)
        }
      })

      new Notify().success('Gelukt!', 'Auto is binnen voor ' + data.dossiers.length + ' dossier' + (data.dossiers.length > 1 ? 's' : '') + ' gewijzigd')
    },

    findDossierIndex (id: number): number {
      return this.dossiers.findIndex(d => d.id === id)
    },

    showNewDossierModal () {
      this.isNewDossierModal = true
    },

    hideNewDossierModal () {
      this.isNewDossierModal = false
    },

    showNewFileManualModal () {
      this.isNewFileManualModal = true
    },

    hideNewFileManualModal () {
      this.isNewFileManualModal = false
    },

    onDossierCreated (dossier: DossierModel) {
      this.navigateToEditDossier(dossier.id)
    },

    navigateToEditDossier (id: number) {
      this.$router.push({ name: 'dossier_edit', params: { id: id.toString() } })
    }
  }
})
