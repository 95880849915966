







import Vue from 'vue'

export default Vue.extend({
  data () {
    return {
      dossierStore: this.$store.state.dossier,
      mounted: false
    }
  },
  mounted () {
    this.mounted = true
  },
  methods: {
    setBpmCalculatorModalActive (active: boolean) {
      this.$store.commit('dossier/setIsBpmCalculatorModalActive', active)
    }
  }
})
