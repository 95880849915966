import { BaseEnum } from './BaseEnum'

interface Options {
  text: string;
  value: number;
}

export class DossierAttachmentEnum extends BaseEnum {
  TemporaryDocument: Options = {
    text: 'Tijdelijk document',
    value: 0
  }

  Datenblatt: Options = {
    text: 'Datenblatt',
    value: 1
  }

  LicenseCard: Options = {
    text: 'Kentekencard',
    value: 2
  }

  KfzLetter: Options = {
    text: 'Originele KFZ brief',
    value: 3
  }

  Export: Options = {
    text: 'Uitvoerdeel (Kentekenbewijs deel II)',
    value: 4
  }

  Additional: Options = {
    text: 'Overig document',
    value: 5
  }

  Prufungszertifikat: Options = {
    text: 'Prüfungszertifikat',
    value: 6
  }

  EgFile: Options = {
    text: 'EG dossier',
    value: 7
  }

  getAll (): Array<any> {
    return [
      this.TemporaryDocument,
      this.Datenblatt,
      this.LicenseCard,
      this.KfzLetter,
      this.Export,
      this.Additional,
      this.Prufungszertifikat,
      this.EgFile
    ]
  }

  getByValue (value: number) {
    const list = this.getAll()

    return super.getItemByValue(list, value)
  }
}
