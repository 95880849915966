import { client } from '../client'
import { DossierModel } from '@/globals/models/DossierModel'
import { BaseService } from './base-service'

export const DossierService = {
  _apiEndpoint: BaseService.apiEndpoint + 'file/',

  async getDossiers (startDate: Date|null, endDate: Date|null) {
    let url = this._apiEndpoint
    if (startDate !== null && endDate !== null) {
      url = url + '?dateStart=' + startDate.toDateString() + '&dateEnd=' + endDate.toDateString()
    }

    return await client.get(url)
  },

  async getDossier (id: number|string) {
    const url = this._apiEndpoint + id

    return await client.get(url)
  },

  async deleteDossier (id: number) {
    const url = this._apiEndpoint + id

    return await client.delete(url)
  },

  async deleteDossiers (ids: Array<number>) {
    const url = this._apiEndpoint + '?ids=' + ids.toString()

    return await client.delete(url)
  },

  async updateDossier (id: number|string, dossier: DossierModel) {
    const url = this._apiEndpoint + id

    return await client.put(url, dossier)
  },

  async updateDossiersStatus (ids: Array<number>, status: number) {
    const url = this._apiEndpoint + 'status?status=' + status

    return await client.put(url, ids)
  },

  async createDossier (data: any) {
    const url = this._apiEndpoint

    return await client.post(url, data)
  },

  async uploadDossier (data: any) {
    const url = this._apiEndpoint + 'upload'

    return await client.post(url, data)
  },

  async toggleArrivedAtHub (ids: Array<number>, isArrived: boolean) {
    const url = this._apiEndpoint + 'togglearrivedathub?ids=' + ids.toString() + '&toggled=' + isArrived

    return await client.post(url)
  },

  async uploadDossierAttachment (dossierId: number|string, type: number, data: any) {
    const url = this._apiEndpoint + dossierId + '/upload/manual?type=' + type

    return await client.post(url, data)
  },

  async deleteDossierAttachment (dossierId: number|string, id: number) {
    const url = this._apiEndpoint + dossierId + '/attachment/' + id

    return await client.delete(url)
  },

  getUploadMultiDossiersByTemporaryDocumentUrl (): string {
    return this._apiEndpoint + 'upload'
  },

  getUploadMultiDossiersByDocument2Url (): string {
    return this._apiEndpoint + 'upload/document2'
  },

  getUploadMultiDossiersByLicenseCardUrl (): string {
    return this._apiEndpoint + 'upload/licensecard'
  },

  getUploadMultiDossiersByAdesaUrl (): string {
    return this._apiEndpoint + 'upload/adesa'
  },

  getUploadMultiDossiersByGaikUrl (): string {
    return this._apiEndpoint + 'upload/gaik'
  },

  // todo: change endpoint
  getUploadMultiDossiersByEGBatch (): string {
    return this._apiEndpoint + 'upload/eg'
  },

  async uploadLicenseCard (data: any) {
    const url = this._apiEndpoint + 'upload/licensecard'

    return await client.post(url, data)
  },

  async uploadTemporaryFile (dossierId: number, data: any) {
    const url = this._apiEndpoint + dossierId + '/upload/document'

    return await client.post(url, data)
  },

  async requestApk (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'requestapk'

    return await client.post(url, dossierIds)
  },

  async requestGas3to4Years (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'benzine34'

    return await client.post(url, dossierIds)
  },

  async requestAssignmentAucotras (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'aucotrasrequest'

    return await client.post(url, dossierIds)
  },

  async sendMissingApprovalType (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'requestapprovaltypenumber'

    return await client.post(url, dossierIds)
  }
}
