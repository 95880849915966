import { BaseModel } from '@/globals/models/BaseModel'

export class AttachmentModel extends BaseModel {
    id = null as null|number
    file_id = null as null|number
    type = 0
    file_name = ''
    url = ''

    setData (data?: any) {
      if (data) {
        this.id = data.id
        this.file_id = data.file_id
        this.type = data.type
        this.file_name = data.file_name
        this.url = data.url
      }
    }

    getErrorEntity () {
      return super.generateErrorEntity(this)
    }

    fromResponse (data: any) {
      this.setData(data)

      return this
    }
}
