import { client } from '../client'
import { BaseService } from './base-service'

export const DatenblattService = {
  _apiEndpoint: BaseService.apiEndpoint + 'datenblatt/',

  async requestDatenblatt (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'requested'

    return await client.post(url, dossierIds)
  },

  async requestCompleteDatenblattAchim (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'complete'

    return await client.post(url, dossierIds)
  },

  async requestTiresizeDatenblattAchim (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'tiresize'

    return await client.post(url, dossierIds)
  },

  async approveRequestDatenblatt (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'requestapproval'

    return await client.post(url, dossierIds)
  },

  async approveDatenblatt (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'approve'

    return await client.post(url, dossierIds)
  },

  async denyDatenblatt (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'deny'

    return await client.post(url, dossierIds)
  },

  async sendWltp (dossierIds: Array<number>) {
    const url = this._apiEndpoint + 'wltp'

    return await client.post(url, dossierIds)
  }
}
