export const LicenseHelper = {
  /* Not my code bruh.
    Code taken from kentekencheck.nl
  */
  toLicensePlateStripes (tmp: any) {
    tmp = this.str_replaceAll(this.str_replaceAll(tmp, ' ', ''), '-', '')

    let i = 0
    let total_kenteken = ''

    while (typeof tmp[i] !== 'undefined') {
      const count1 = (total_kenteken.match(/is/g) || []).length
      if (typeof tmp[i + 1] !== 'undefined' && this.isNumeric(tmp[i]) !== this.isNumeric(tmp[i + 1]) && tmp[i] !== '-' && tmp[i + 1] !== '-' && count1 < 2) {
        total_kenteken += tmp.substr(i, 1) + '-'
      } else {
        total_kenteken += tmp.substr(i, 1)
      }
      i++
    }
    const count2 = (total_kenteken.match(/is/g) || []).length
    if (count2 < 2) {
      let i2 = 0
      tmp = total_kenteken.split('-')
      while (typeof tmp[i2] !== 'undefined') {
        if (tmp[i2].length === 4) {
          total_kenteken = this.str_replaceAll(total_kenteken, tmp[i2], tmp[i2][0] + tmp[i2][1] + '-' + tmp[i2][2] + tmp[i2][3])
        }
        i2++
      }
    }

    if (total_kenteken.substr(0, 1) === '-') {
      total_kenteken = total_kenteken.substr(1)
    }
    tmp = total_kenteken.split('-')
    if (tmp.length >= 4) {
      total_kenteken = tmp[0] + '-' + tmp[1] + '-'
      i = 2
      while (typeof tmp[i] !== 'undefined') {
        total_kenteken += tmp[i]
        i++
      }
    }

    return total_kenteken.toUpperCase()
  },
  isNumeric (str: string) {
    return !isNaN(parseFloat(str))
  },
  str_replaceAll (str: any, search: string, replacement: string) {
    if (typeof str === 'undefined') return str
    return str.replace(new RegExp(search, 'g'), replacement)
  }
}
