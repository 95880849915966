



























import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'

export default Vue.extend({
  components: {
    PrimaryButton
  }
})
