




















import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import Dropdown from '@/globals/components/form/Dropdown.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { DossierService } from '@/network/api/dossier-service'
import { HttpStatus } from '@/globals/enums/HttpStatus'

export default Vue.extend({
  components: {
    PrimaryModal,
    Dropdown,
    PrimaryButton
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      answers: [
        { text: 'Ja', value: true },
        { text: 'Nee', value: false }
      ],
      selected: null as null|boolean
    }
  },
  methods: {
    async submitForm () {
      if (this.selected !== null) {
        const ids = [] as Array<number>
        this.items.forEach((item: any) => {
          ids.push(item.id)
        })

        await DossierService.toggleArrivedAtHub(ids, this.selected).then((response: any) => {
          if (response.status === HttpStatus.OK) {
            this.$emit('in_hub_changed', { dossiers: this.items, inHub: this.selected })
          }
        })
      }
    }
  }
})
