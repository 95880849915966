

















































import Vue from 'vue'

export default Vue.extend({
  components: {
  },
  data () {
    return {
      userStore: this.$store.state.user
    }
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$store.commit('dossier/unsetFilter')
      this.$router.replace({ name: 'login' })
    }
  }
})
