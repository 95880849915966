var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:{
  'primary--btn': true,
  'outline': _vm.outline,
  'width--small': _vm.widthSmall,
  'width--large': _vm.widthLarge,
  'width--full': _vm.widthFull,
  'height--large': _vm.heightLarge,
  'color--red': _vm.colorRed,
  'color--white': _vm.colorWhite,
  'color--success': _vm.colorSuccess,
  'color--warning': _vm.colorWarning,
  'color--error': _vm.colorError,
  'color--primary': _vm.colorPrimary,
  'color--info': _vm.colorInfo,
  'color--grey': _vm.colorGrey,
  'color-text--primary': _vm.colorTextPrimary,
  'color-text--error': _vm.colorTextError,
  'disabled': _vm.disabled || (_vm.loadingState.isLoading && _vm.isLoading),
  'is--label': _vm.label
},style:(_vm._style),attrs:{"type":_vm.type,"disabled":_vm.disabled || (_vm.loadingState.isLoading && _vm.isLoading)},on:{"click":_vm.handleClick}},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingState.isLoading && _vm.isLoading),expression:"loadingState.isLoading && isLoading"}],staticClass:"fas fa-circle-notch fa-spin"}),(_vm.icon)?_c('i',{class:_vm.icon}):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" "),_vm._t("default"),(_vm.prependIcon)?_c('i',{class:_vm.prependIcon + ' icon--prepend'}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }