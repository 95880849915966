

















































import Vue from 'vue'
import PrimaryModal from '@/globals/components/modals/PrimaryModal.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import StepChoices from './step-choices/StepChoices.vue'
import UploadDossiers from './upload-dossiers/UploadDossiers.vue'
import UploadStream from './upload-stream/UploadStream.vue'

export default Vue.extend({
  components: {
    PrimaryModal,
    PrimaryButton,
    StepChoices,
    UploadDossiers,
    UploadStream
  },
  props: {
    _clients: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isClient: this.$store.getters['user/isClient'],
      step: 0,
      stepZeroSelectedValue: 0
    }
  }
})
