
















import Vue from 'vue'
import { DossierModel } from '@/globals/models/DossierModel'

export default Vue.extend({
  props: {
    _dossier: {
      type: DossierModel,
      required: true
    }
  },
  computed: {
    isAdministration (): boolean {
      return this.$store.getters['user/isAdministration']
    },
    isEmployee (): boolean {
      return this.$store.getters['user/isEmployee']
    },
    isClient (): boolean {
      return this.$store.getters['user/isClient']
    },
    isPetrolCar (): boolean {
      return ((this.isAdministration || this.isEmployee) && this._dossier.vehicle.isPetrolCarDanger())
    },
    isWltpCar (): boolean {
      return ((this.isAdministration || this.isEmployee) && this._dossier.vehicle.has_wltp)
    },
    isDangerous (): boolean {
      return this.isClient && this._dossier.vehicle.is_dangerous
    },
    isApkInvalid (): boolean {
      return !this._dossier.vehicle.isOreh() && !this._dossier.vehicle.isApkValid()
    },
    isNoApprovalType (): boolean {
      return this.isAdministration && this._dossier.vehicle.approval_type === null
    }
  }
})
