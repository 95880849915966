





















import Vue from 'vue'
import BlankSlate from '@/globals/components/blank-slates/BlankSlate.vue'

export default Vue.extend({
  props: {
    clients: {
      type: Array,
      required: false
    }
  },
  components: {
    BlankSlate
  },
  methods: {
  }
})
