












































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { ClientModel } from '@/globals/models/ClientModel'
import { DossierModel } from '@/globals/models/DossierModel'
import Counter from './components/counter/Counter.vue'
import LatestDossiers from './components/latest-dossiers/LatestDossiers.vue'
import { StatsService } from '../../network/api/stats-service copy'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import LatestClients from './components/latest-clients/LatestClients.vue'

export default Vue.extend({
  components: {
    Counter,
    LatestDossiers,
    PrimaryButton,
    LatestClients
  },
  data () {
    return {
      isEssentialsLoaded: false,
      isAdministration: this.$store.getters['user/isAdministration'],
      isEmployee: this.$store.getters['user/isEmployee'],
      isClient: this.$store.getters['user/isClient'],
      counters: {
        dossiers_total: 0,
        dossiers_done: 0,
        dossiers_in_progress: 0,
        dossiers_in_request: 0,
        dossiers_invoice_sent: 0
      },
      latestDossiers: [] as Array<DossierModel>,
      latestClients: [] as Array<ClientModel>
    }
  },
  beforeMount () {
    this.loadEssentails()
  },
  methods: {
    async loadEssentails () {
      this.isEssentialsLoaded = false
      await this.fetchStats()
      this.isEssentialsLoaded = true
    },

    async fetchStats () {
      await StatsService.getDashboardStats().then((response: any) => {
        if (response.status === HttpStatus.OK) {
          const data = response.data
          this.counters.dossiers_total = data.files_amount
          this.counters.dossiers_done = data.done
          this.counters.dossiers_in_progress = data.in_progress
          this.counters.dossiers_in_request = data.in_request
          this.counters.dossiers_invoice_sent = data.last_five_invoice_sent

          this.setLastestDossiers(data.last_five)

          if (data.last_clients) {
            this.setLastestClients(data.last_clients)
          }
        }
      })
    },
    setLastestDossiers (dossiersRaw: Array<any>) {
      dossiersRaw.forEach((dossierRaw: any) => {
        const dossier = new DossierModel().fromResponse(dossierRaw)
        this.latestDossiers.push(dossier)
      })
    },
    setLastestClients (clientsRaw: Array<any>) {
      clientsRaw.forEach((clientRaw: any) => {
        const client = new ClientModel().fromResponse(clientRaw)
        this.latestClients.push(client)
      })
    },

    navigateToDossiers () {
      this.$router.replace({ name: 'dossier_overview' }).catch()
    }
  }
})
