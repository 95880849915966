






















































































import Vue from 'vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import DossierDocuments from './components/dossier-documents/DossierDocuments.vue'
import DossierHeader from './components/dossier-header/DossierHeader.vue'
import DossierNotes from './components/dossier-notes/DossierNotes.vue'
import GeneralCarInfo from './components/general-car-info/GeneralCarInfo.vue'
import TemporaryDocument from './components/temporary-document/TemporaryDocument.vue'
import LicenseCard from './components/license-card/LicenseCard.vue'
import { ObjectHelper } from '@/globals/helpers/ObjectHelper'

export default Vue.extend({
  components: {
    DossierHeader,
    TemporaryDocument,
    GeneralCarInfo,
    DossierDocuments,
    DossierNotes,
    LicenseCard
  },
  data () {
    return {
      isEssentialsLoaded: false,
      dossierId: this.$route.params.id,
      dossier: null as null|DossierModel,
      missing: Object,
      isAdesa: this.$store.getters['user/isAdesa']
    }
  },
  beforeMount () {
    this.loadEssentials()
  },
  methods: {
    async loadEssentials () {
      this.isEssentialsLoaded = false
      await this.getDossier()
      this.isEssentialsLoaded = true
    },

    async getDossier () {
      await DossierService.getDossier(this.dossierId).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.dossier = new DossierModel().fromResponse(response.data)
        }
      })
    },

    updateDossier (dossier: DossierModel) {
      this.dossier = dossier
    }

  },
  computed: {
    checkMissingData: function (): object|null {
      const obj = this.dossier?.hasMissingData(this.isAdesa)
      return !ObjectHelper.isEmpty(obj) ? obj : null
    }
  }
})
