import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/login/Login.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import Dossier from '../views/dossier/Dossier.vue'
import DossierOverview from '../views/dossier/overview/Overview.vue'
import DossierEdit from '../views/dossier/edit/Edit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  /**
   * Auth
   */
  {
    path: '/login',
    name: 'login',
    component: Login
  },

  /**
   * Dashboard
   */
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },

  /**
   * Dossier
   */
  {
    path: '/dossiers',
    name: 'dossier',
    component: Dossier,
    redirect: { name: 'dossier_overview' },
    children: [
      {
        path: '',
        name: 'dossier_overview',
        component: DossierOverview
      },
      {
        path: ':id',
        name: 'dossier_edit',
        component: DossierEdit
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function isAuthenticated (): boolean {
  return localStorage.getItem('token') !== null
}

router.beforeEach((to, from, next) => {
  if (!to.path.startsWith('/login') && !isAuthenticated()) {
    next({ name: 'login' })
  } else if (to.path.startsWith('/login') && isAuthenticated()) {
    next({ name: 'dossier_overview' })
  } else {
    next()
  }
})

export default router
