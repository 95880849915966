

































































import Vue from 'vue'
import BlankSlate from '@/globals/components/blank-slates/BlankSlate.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import { DossierAttachmentEnum } from '@/globals/enums/DossierAttachment'
import { Notify } from '@/globals/controllers/Notification'
import { LicenseHelper } from '@/globals/helpers/LicenseHelper'

export default Vue.extend({
  components: {
    PrimaryButton,
    InputField,
    BlankSlate
  },
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      editDossier: new DossierModel(this._dossier),
      errors: this._dossier.getErrorEntity(),
      attachmentEnum: new DossierAttachmentEnum(),
      licenseHelper: LicenseHelper,
      tempDoc: null,
      uploadErrorMessage: ''
    }
  },
  watch: {
    _dossier () {
      this.setDossierAsEditDossier()
    }
  },
  computed: {
    isAllFieldsEmpty (): boolean {
      return !this.editDossier.license_plate && !this.editDossier.ascription_code_part_one && !this.editDossier.ascription_code_part_two
    },
    tempDocAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.TemporaryDocument.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    }
  },
  methods: {
    setDossierAsEditDossier () {
      this.editDossier = new DossierModel(this._dossier)
    },

    clearErrors () {
      this.errors = this._dossier.getErrorEntity()
    },

    selectTempDocument () {
      const element = this.$refs.uploadTempDocument as any
      element.click()
    },

    async onSelectedTempFile (e: any) {
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        await this.uploadTemporaryDocument(file)
      }
      e.target.value = null
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadTemporaryDocument (file: any) {
      const formData = new FormData()
      formData.append('file', file)
      this.uploadErrorMessage = ''
      await DossierService.uploadTemporaryFile(this.editDossier.id, formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          new Notify().success('Gelukt!', 'Het tijdelijk document is verwerkt')
          this.$emit('updated', new DossierModel().fromResponse(response.data))
        }
        if (response.status === HttpStatus.BAD_REQUEST) {
          this.uploadErrorMessage = response.data
        }
      })
    },

    async updateDossier () {
      this.clearErrors()
      await DossierService.updateDossier(this._dossier.id, this.editDossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.setIsEditing(false)
          this.$emit('updated', new DossierModel().fromResponse(response.data))
        }

        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },

    setIsEditing (isEditing: boolean) {
      this.isEditing = isEditing
    },

    openDocument (url: string) {
      window.open(url, '_blank')
    }
  }
})
