import moment from 'moment'

export const DateHelper = {
  toLocaleDateString (date: Date|string): string {
    if (typeof date === 'string' || date instanceof String) {
      date = this.toDate(date)
    }

    const options = { year: 'numeric', month: 'short', day: 'numeric' } as any

    return date.toLocaleDateString(undefined, options)
  },
  toMoment (date: Date|string, format?: string): moment.Moment {
    return moment(date, format)
  },
  toDate (date: Date|string): Date {
    return moment(date).toDate()
  },
  getTimeFromDateTime (dateTime: Date): string {
    return dateTime.getHours() + ':' + dateTime.getMinutes()
  },
  toUnix (dateTime: Date|string): number {
    return moment(dateTime).unix()
  },
  toDateTime (date: Date|string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  },
  toUTCDateTime (date: Date|string): string {
    return moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
  },
  toDateString (date: Date|string) {
    return moment(date).format('YYYY-MM-DD')
  },
  toUTCDateString (date: Date|string) {
    return moment(date).utc().format('YYYY-MM-DD')
  },

  toLocaleDateNums (date: string|Date) {
    if (typeof date === 'string' || date instanceof String) {
      date = this.toDate(date.toString())
    }

    const options = { year: 'numeric', month: 'numeric', day: 'numeric' } as any
    return date.toLocaleDateString(undefined, options)
  },

  resetTime (date: string|Date): Date {
    if (typeof date === 'string' || date instanceof String) {
      date = this.toDate(date.toString())
    }

    date.setHours(0, 0, 0, 0)

    return date
  },

  timeSince (date: any) {
    const curDate = new Date()
    const seconds = Math.floor((curDate as any - date) / 1000)

    let interval = seconds / 31536000

    if (interval > 1) {
      return Math.floor(interval) + ' jaar'
    }
    interval = seconds / 2592000
    if (interval > 1) {
      return Math.floor(interval) + ' maanden'
    }
    interval = seconds / 86400
    if (interval > 1) {
      return Math.floor(interval) + ' dagen'
    }
    interval = seconds / 3600
    if (interval > 1) {
      return Math.floor(interval) + ' uur'
    }
    interval = seconds / 60
    if (interval > 1) {
      return Math.floor(interval) + ' minuten'
    }
    return Math.floor(seconds) + ' seconden'
  }
}
