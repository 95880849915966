

































import BlankSlate from '@/globals/components/blank-slates/BlankSlate.vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import { Notify } from '@/globals/controllers/Notification'
import { DossierAttachmentEnum } from '@/globals/enums/DossierAttachment'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import Vue from 'vue'

export default Vue.extend({
  components: {
    PrimaryButton,
    // InputField,
    BlankSlate
  },
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      editDossier: new DossierModel(this._dossier),
      errors: this._dossier.getErrorEntity(),
      dateHelper: DateHelper,
      attachmentEnum: new DossierAttachmentEnum()
    }
  },
  watch: {
    _dossier () {
      this.setDossierAsEditDossier()
    }
  },
  computed: {
    isCardScanned (): boolean {
      return this.editDossier.isAttachmentUploaded(this.attachmentEnum.LicenseCard.value)
    },
    licenseCardAttachment (): null|object {
      const attachments = this.editDossier.getAttachmentsByType(this.attachmentEnum.LicenseCard.value)
      if (attachments.length > 0) {
        return attachments[0]
      } else {
        return null
      }
    }
  },
  beforeMount () {
    this.setDossierAsEditDossier()
  },
  methods: {
    setDossierAsEditDossier () {
      this.editDossier = new DossierModel(this._dossier)
    },

    clearErrors () {
      this.errors = this._dossier.getErrorEntity()
    },

    selectLicenseCardDocument () {
      const element = this.$refs.uploadLicenseCardDocument as any
      element.click()
    },

    async onSelectedLicenseCardFile (e: any) {
      const file = e.target.files[0]

      if (file && this.validateFileSize(file)) {
        await this.uploadLicenseCardDocument(file)
      }
    },
    validateFileSize (file: any) {
      const size = file.size / 1024 / 1024 // in MB

      if (size > 5) {
        alert('Document mag niet groter zijn dan 5 MB')
      } else {
        return true
      }

      return false
    },

    async uploadLicenseCardDocument (file: any) {
      const formData = new FormData()
      formData.append('file', file)
      await DossierService.uploadLicenseCard(formData).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          let isThisDossier = false

          response.data.updated.forEach((dossierRaw: any) => {
            const dossier = new DossierModel().fromResponse(dossierRaw)

            if (dossier.id === this.editDossier.id) {
              isThisDossier = true
              this.$emit('updated', dossier)
            }
          })

          if (isThisDossier) {
            this.isEditing = false
          } else {
            new Notify().error('Verkeerd bestand', 'Het geüploade bestand hoort niet bij dit dossier.')
          }
        }
      })
    },

    openDocument (url: string) {
      window.open(url, '_blank')
    },

    setIsEditing (isEditing: boolean) {
      this.isEditing = isEditing
    }
  }
})
