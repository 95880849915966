







































































































































































































import Vue from 'vue'
import PrimaryButton from '@/globals/components/buttons/PrimaryButton.vue'
import TextButton from '@/globals/components/buttons/TextButton.vue'
import DatePicker from '@/globals/components/datepicker/DatePicker.vue'
import InputField from '@/globals/components/form/InputField.vue'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { DateHelper } from '@/globals/helpers/DateHelper'
import { DossierModel } from '@/globals/models/DossierModel'
import { DossierService } from '@/network/api/dossier-service'
import { PriceHelper } from '@/globals/helpers/PriceHelper'

export default Vue.extend({
  components: {
    PrimaryButton,
    InputField,
    TextButton,
    DatePicker
  },
  props: {
    _dossier: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isEditing: false,
      editDossier: new DossierModel(),
      errors: this._dossier.getErrorEntity(),
      dateHelper: DateHelper,
      priceHelper: PriceHelper,
      isBpmCalculatorModal: false
    }
  },
  watch: {
    _dossier () {
      this.setDossierAsEditDossier()
    }
  },
  beforeMount () {
    this.setDossierAsEditDossier()
  },
  methods: {
    setDossierAsEditDossier () {
      this.editDossier.setData(this._dossier)
    },

    clearErrors () {
      this.errors = this._dossier.getErrorEntity()
    },

    async updateDossier () {
      this.clearErrors()
      await DossierService.updateDossier(this._dossier.id, this.editDossier).then((response: any) => {
        if (response.status === HttpStatus.OK) {
          this.setIsEditing(false)
          this.$emit('updated', new DossierModel().fromResponse(response.data))
        }

        if (response.status === HttpStatus.BAD_REQUEST) {
          this.errors = {
            ...this.errors,
            ...response.data
          }
        }
      })
    },

    setIsEditing (isEditing: boolean) {
      this.isEditing = isEditing
    },

    openBpmCalculator () {
      window.open('https://www.vwe.nl/Autobedrijf/In-en-verkoop/bpm-calculator-export', '_blank')
    }
  }
})
