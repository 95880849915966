import { ObjectHelper } from '../helpers/ObjectHelper'
import { BaseEnum, Enum } from './BaseEnum'
export class DossierStatusEnum extends BaseEnum {
  LicensePlatesAndCardsReceived: Enum = {
    text: '1. Kentekenplaten en kentekencards binnen',
    value: 0
  }

  WaitingForApk: Enum = {
    text: '2. Wacht op APK',
    value: 1
  }

  WaitingForDatenBlatt: Enum = {
    text: '3. Wachten op goedkeuring Datenblatt',
    value: 2
  }

  InRequest: Enum = {
    text: '4. KFZ in aanvraag',
    value: 3
  }

  SentToGermany: Enum = {
    text: '5. Verstuurd naar Duitsland',
    value: 4
  }

  SentFromGermany: Enum = {
    text: '6. Verstuurd vanuit Duitsland',
    value: 5
  }

  BpmList: Enum = {
    text: '7. BPM lijst',
    value: 6
  }

  OriginalKfz: Enum = {
    text: '8. Originele KFZ',
    value: 7
  }

  Done: Enum = {
    text: '9. Afgehandeld',
    value: 8
  }

  InvoiceSent: Enum = {
    text: '10. Factuur verzonden',
    value: 9
  }

  InvoiceFulfilled: Enum = {
    text: '11. Factuur betaald',
    value: 10
  }

  ReadyForTransport: Enum = {
    text: '12. Klaar voor transport',
    value: 11
  }

  TransportProofToGermany: Enum = {
    text: '13. Transportbewijs naar Duitsland',
    value: 12
  }

  TransportProofToBuyer: Enum = {
    text: '14. Transportbewijs naar koper',
    value: 13
  }

  OrderIn: Enum = {
    text: '15. Opdracht binnen',
    value: 14
  }

  OnHold: Enum = {
    text: '16. On hold',
    value: 15
  }

  WaitingTypeApprovalMissing: Enum = {
    text: '17. Wachtend op foto van typenplaatje',
    value: 16
  }

  ApkExpired: Enum = {
    text: '18. APK verlopen',
    value: 17
  }

  ApkRequested: Enum = {
    text: '19. APK aangevraagd',
    value: 18
  }

  MilesMissing: Enum = {
    text: '20. Kilometerstand mist',
    value: 19
  }

  DatenblattMissing: Enum = {
    text: '21. Datenblatt mist',
    value: 20
  }

  DatenblattApproved: Enum = {
    text: '22. Datenblatt akkoord',
    value: 21
  }

  DatenblattDenied: Enum = {
    text: '23. Datenblatt afgekeurd',
    value: 22
  }

  WaitingFueltypeGas: Enum = {
    text: '24. Wachten op antwoord gasvoertuig',
    value: 23
  }

  Gas3to4YearsRequested: Enum = {
    text: '25. Benzine 3 tot 4 jaar aangevraagd',
    value: 24
  }

  DatenblattRequested: Enum = {
    text: '26. Datenblatt aangevraagd',
    value: 25
  }

  MissingPieces: Enum = {
    text: '27. Missende stukken',
    value: 26
  }

  Complete: Enum = {
    text: '28. Compleet',
    value: 99
  }

  getAll (): Array<any> {
    const list = [
      this.OrderIn,
      this.MissingPieces,
      this.LicensePlatesAndCardsReceived,
      this.OnHold,
      this.WaitingForApk,
      this.ApkExpired,
      this.ApkRequested,
      this.DatenblattRequested,
      this.Gas3to4YearsRequested,
      this.BpmList,
      this.WaitingTypeApprovalMissing,
      this.WaitingFueltypeGas,
      this.WaitingForDatenBlatt,
      this.SentToGermany,
      this.SentFromGermany,
      this.TransportProofToBuyer,
      this.TransportProofToGermany,
      this.ReadyForTransport,
      this.InRequest,
      this.OriginalKfz,
      this.Done,
      this.InvoiceSent,
      this.InvoiceFulfilled
    ]

    return this.getSortedList(list)
  }

  getStatusSelection (): Array<any> {
    const list = ObjectHelper.cloneObject([
      this.OrderIn,
      this.MissingPieces,
      this.LicensePlatesAndCardsReceived,
      this.OnHold,
      this.WaitingForApk,
      this.ApkRequested,
      this.DatenblattRequested,
      this.Gas3to4YearsRequested,
      this.SentToGermany,
      this.SentFromGermany,
      this.InRequest,
      this.OriginalKfz,
      this.Done,
      this.InvoiceSent,
      this.InvoiceFulfilled
    ])

    return this.getSortedList(list)
  }

  getStatusSelectionAdesa (): Array<any> {
    const list = ObjectHelper.cloneObject([
      this.OrderIn,
      this.MissingPieces,
      this.LicensePlatesAndCardsReceived,
      this.OnHold,
      this.WaitingForApk,
      this.ApkRequested,
      this.DatenblattRequested,
      this.Gas3to4YearsRequested,
      this.SentToGermany,
      this.SentFromGermany,
      this.InRequest,
      this.OriginalKfz,
      this.Done,
      this.InvoiceSent,
      this.InvoiceFulfilled,
      this.Complete
    ])

    return this.getSortedList(list)
  }

  getSortedList = (list: Array<Enum>) => list.sort((a, b) => a.value - b.value)

  toNumList (list: Array<Enum>): Array<Enum> {
    list = list.sort((a, b) => a.value - b.value)
    for (let i = 0; i < list.length; i++) {
      list[i].text = ((list[i].value) + '. ' + list[i].text)
    }

    return list
  }

  getByValue (value: number): Enum {
    const list = this.getAll()

    return super.getItemByValue(list, value)
  }
}
