import axios from 'axios'
import store from '../store/index'
import { HttpStatus } from '@/globals/enums/HttpStatus'
import { AuthService } from './api/auth-service'
import { Notify } from '../globals/controllers/Notification'

export const client = {
  clearStorage () {
    localStorage.clear()
    location.reload()
  },

  getToken (): string|null {
    return localStorage.getItem('token')
  },

  storeToken (token: string) {
    localStorage.setItem('token', token)
  },

  handleErrors (response: any) {
    if (response.status === HttpStatus.BAD_REQUEST) {
      new Notify().entityError()
    } else {
      new Notify().error(response.data.message ?? 'Er heeft een fout plaatsgevonden', response.status)
    }

    return response
  },

  async get (endPoint: string, params?: object, reqOptions?: object) {
    this.enableLoading()

    const reqData = { params: params, headers: { Authorization: 'Bearer ' + this.getToken() }, ...reqOptions }

    return new Promise((resolve) => {
      axios.get(endPoint, reqData)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            AuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.get(endPoint, params))
              } else {
                this.clearStorage()
              }
            })
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  async put (endPoint: string, data = {}) {
    this.enableLoading()

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      }
    }
    return new Promise((resolve) => {
      axios.put(endPoint, data, headers)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            AuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.put(endPoint, data))
              } else {
                this.clearStorage()
              }
            })
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  async delete (endPoint: string) {
    this.enableLoading()

    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      }
    }
    return new Promise((resolve) => {
      axios.delete(endPoint, headers)
        .then((response: any) => {
          resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED) {
            AuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.delete(endPoint))
              } else {
                this.clearStorage()
              }
            })
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  async post (endPoint: string, body = {} as any, refreshing = false, reqOptions?: object) {
    this.enableLoading()
    const headersData = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: 'Bearer ' + this.getToken()
      },
      ...reqOptions
    }

    return new Promise((resolve) => {
      axios.post(endPoint, body, headersData)
        .then((response: any) => {
          return resolve(response)
        })
        .catch((error: any) => {
          if (error.response.status === HttpStatus.UNAUTHORIZED && !refreshing) {
            AuthService.refresh().then((response: any) => {
              if (response.status === HttpStatus.OK) {
                return resolve(this.post(endPoint, body, false, reqOptions))
              } else {
                this.clearStorage()
              }
            })
          } else if (error.response.status === HttpStatus.UNAUTHORIZED && refreshing) {
            this.clearStorage()
          } else {
            return resolve(this.handleErrors(error.response))
          }
        })
        .finally(
          () => this.disableLoading()
        )
    })
  },

  enableLoading () {
    store.commit('loading/setIsLoading', true)
  },
  disableLoading () {
    store.commit('loading/setIsLoading', false)
  }
}
