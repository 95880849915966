









import Vue from 'vue'

export default Vue.extend({
  props: {
    status: {
      type: String,
      required: false
    },
    isError: {
      type: Boolean,
      required: false
    }
  }
})
