import { client } from '../client'
import { BaseService } from './base-service'

export const EgService = {
  _apiEndpoint: BaseService.apiEndpoint + 'eg/',

  getUploadEgUrl (): string {
    return this._apiEndpoint + 'upload'
  },

  async uploadEg (data: any) {
    const url = this._apiEndpoint + 'upload'

    return await client.post(url, data)
  }
}
